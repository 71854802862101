import { FetchResult } from '@apollo/client';

import { YearGroupQueries } from './year-group.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { IYearGroupDto } from '../types/year-group.dto';

export default class YearGroupService extends BaseConquestApiService {
    public async getAllYearGroups(): Promise<FetchResult<{ getAllYearGroups: IYearGroupDto[] }>> {
        return this.graphqlClient.query({
            query: YearGroupQueries.getAllYearGroups
        });
    }
}
