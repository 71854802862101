import CountryService from './country.service';
import { infiniteStaleTime } from '../../../common/constants';
import { useConquestQuery } from '../../../hooks/use-conquest-query';

export const getCountriesKey = 'getCountries';

const getStatesKey = 'getStatesForCountry';
const countryService = new CountryService();

export const useGetAllCountries = () => {
    return useConquestQuery(
        async () => {
            const { data } = await countryService.getAllCountries();

            return data?.getAllCountriesContent;
        },
        getCountriesKey,
        { staleTime: infiniteStaleTime }
    );
};

export const useGetStatesForCountry = () => {
    return useConquestQuery(
        async (code: string) => {
            const { data } = await countryService.getStatesForCountry(code);

            return data.getCountryContentByCode.states;
        },
        getStatesKey,
        { staleTime: infiniteStaleTime }
    );
};
