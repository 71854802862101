import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';

import { TreeSectionChildNodeDto } from '../curriculum/interface';

interface StrandNodeProps {
    nodeDatum: TreeSectionChildNodeDto;
    toggleNode: React.MouseEventHandler<HTMLButtonElement>;
    setSelectedNodeDatum: React.Dispatch<React.SetStateAction<TreeSectionChildNodeDto | undefined>>;
    selectedNodeDatum?: TreeSectionChildNodeDto;
    setStrandAbbreviation: React.Dispatch<any>;
    setSelectedMasterCourseDescription?: React.Dispatch<React.SetStateAction<any>>;
    setSelectedMasterCourseCode?: React.Dispatch<React.SetStateAction<any>>;
}

const ForeignObjectNode = ({
    setStrandAbbreviation,
    selectedNodeDatum,
    setSelectedNodeDatum,
    setSelectedMasterCourseDescription,
    setSelectedMasterCourseCode,
    nodeDatum,
    toggleNode
}: StrandNodeProps) => {
    return (
        <g>
            <circle r={10} />
            <foreignObject width={200} height={'100%'}>
                <Box justifyContent={'center'} alignItems={'center'} padding={3} margin={3} border={'1px solid black'} backgroundColor={'#dedede'}>
                    <Button
                        onClick={() => {
                            setSelectedNodeDatum(nodeDatum);
                            setStrandAbbreviation(nodeDatum.code);
                            setSelectedMasterCourseDescription && setSelectedMasterCourseDescription(nodeDatum.name);
                            setSelectedMasterCourseCode && setSelectedMasterCourseCode(nodeDatum.code);
                        }}
                        width={'100%'}
                        size={'sm'}
                        isDisabled={nodeDatum.id === selectedNodeDatum?.id}
                    >
                        {nodeDatum.id !== selectedNodeDatum?.id ? 'Select node' : 'Node selected'}
                    </Button>
                    <Text my={2} justifyContent={'center'} fontSize={'sm'}>
                        {nodeDatum.code || 'No code provided'}
                    </Text>
                    <Text my={2} justifyContent={'center'} fontSize={'sm'}>
                        {nodeDatum.name}
                    </Text>
                    {nodeDatum.children?.length > 0 && (
                        <Button width={'100%'} onClick={toggleNode}>
                            {nodeDatum.__rd3t.collapsed ? 'Expand' : 'Collapse'}
                        </Button>
                    )}
                </Box>
            </foreignObject>
        </g>
    );
};

export default ForeignObjectNode;
