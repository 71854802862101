import StrandDeliveryService from './strand.delivery.service';
import StrandService from './strand.service';
import { useConquestMutator } from '../../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../../hooks/use-conquest-query';
import { AddStrandInput, StrandListSearchArgs, UpdateStrandInput } from '../strand.interface';

const strandService = new StrandService();
const strandDeliveryService = new StrandDeliveryService();
const getAuthoredStrandsKey = 'getAuthoredStrandsKey';
const getStrandById = 'getStrandByIdKey';
const getAllDeliveredStrandsKey = 'getAllDeliveredStrandsKey';

export const useAddStrand = () => {
    return useConquestMutator(async (input: AddStrandInput) => {
        const { data } = await strandService.addStrand(input);

        return data?.addStrand;
    });
};

export const useUpdateStrand = () => {
    return useConquestMutator(async (input: UpdateStrandInput) => {
        const { data } = await strandService.updateStrand(input);

        return data?.updateStrandByIdContent;
    });
};

export const useAddStrandDeliver = () => {
    return useConquestMutator(async (input: AddStrandInput[]) => {
        const { data } = await strandDeliveryService.addStrandsDeliver(input);

        return data?.addStrandsDeliver;
    });
};

export const useRemoveStrand = () => {
    return useConquestMutator(async (id: string) => {
        const { data, errors } = await strandService.removeStrand(id);

        if (errors) {
            return errors[0].message;
        } else {
            return data?.removeStrandContent;
        }
    });
};

export const useGetAuthoredStrands = () => {
    return useConquestQuery(async (input: StrandListSearchArgs) => {
        const { data } = await strandService.getStrands(input);

        return data?.getStrands;
    }, getAuthoredStrandsKey);
};

export const useGetStrandById = () => {
    return useConquestQuery(async (id: string) => {
        const { data } = await strandService.getStrandById(id);

        return data?.getStrandByIdContent;
    }, getStrandById);
};

export const useGetAllDeliveredStrands = () => {
    return useConquestQuery(async () => {
        const { data } = await strandDeliveryService.getAllStrands();

        return data?.getAllStrandsDeliver;
    }, getAllDeliveredStrandsKey);
};
