import { Avatar, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import md5 from 'md5';
import * as React from 'react';
import { useEffect, useState } from 'react';

import authenticationService from '../../services/authentication.service';
import { IUserDetails } from '../../services/interfaces/user-details';

const UserInfoPanel: React.FC = () => {
    const [userDetails, setUserDetails] = useState<IUserDetails>();
    const emailHash = md5(userDetails?.email ?? '');

    useEffect(() => {
        setUserDetails(authenticationService.getUserDetails());
    }, []);

    return (
        <Flex mt={4} align={'center'}>
            <Avatar size={'sm'} src={`https://www.gravatar.com/avatar/${emailHash}`} />
            <Flex flexDir={'column'} ml={4}>
                <Tooltip label={`${userDetails?.given_name} ${userDetails?.family_name ?? ''}`}>
                    <Heading size={'sm'} isTruncated width={'160px'}>
                        {userDetails?.given_name} {userDetails?.family_name}
                    </Heading>
                </Tooltip>
                <Tooltip label={userDetails?.email}>
                    <Text color={'gray'} fontSize={'xs'} isTruncated width={'160px'}>
                        {userDetails?.email}
                    </Text>
                </Tooltip>
            </Flex>
        </Flex>
    );
};

export default UserInfoPanel;
