/* eslint-disable*/
import { css } from '@emotion/react';

import { mathliveAssetsUrl } from '../common/constants';

/*
  Font taken from Google fonts:
  https://use.typekit.net/bcv1gms.css
*/

// Always use woff2 format if you ever get requested to change these fonts, I have previously tried ttf in the past and it doesn't work

export const fonts = css`
    /* devanagari */
    @font-face {
        font-family: 'SofiaPro-Bold';
        src: url('https://3plearning.getbynder.com/m/747539544663269f/original/SofiaProBold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'SofiaPro-SemiBold';
        src: url('https://3plearning.getbynder.com/m/4e193a216c989a1d/original/SofiaProSemiBold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: 'SofiaProSemiBold';
        src: url('https://3plearning.getbynder.com/m/4e193a216c989a1d/original/SofiaProSemiBold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: 'SofiaPro-Bold';
        src: url('https://3plearning.getbynder.com/m/747539544663269f/original/SofiaProBold.woff2') format('woff2');
        font-display: auto;
        font-weight: 700;
    }

    @font-face {
        font-family: 'SofiaPro-SemiBold';
        src: url('https://3plearning.getbynder.com/m/4e193a216c989a1d/original/SofiaProSemiBold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: 'Sofia Pro';
        src: url('https://3plearning.getbynder.com/m/4a072901fc138f3/original/SofiaProRegular.woff2') format('woff2');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'Sofia Pro';
        src: url('https://3plearning.getbynder.com/m/4a072901fc138f3/original/SofiaProRegular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'SofiaProRegular';
        src: url('https://3plearning.getbynder.com/m/4a072901fc138f3/original/SofiaProRegular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'Sofia-Pro';
        src: url('https://3plearning.getbynder.com/m/4a072901fc138f3/original/SofiaProRegular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_SansSerif-Regular';
        src: url('https://3plearning.getbynder.com/m/1c79541266254ac9/original/KaTeX_SansSerif-Regular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_SansSerif-Italic';
        src: url('https://3plearning.getbynder.com/m/4ca4ea686f3a5bbe/original/KaTeX_SansSerif-Italic.woff2') format('woff2');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_SansSerif-Bold';
        src: url('https://3plearning.getbynder.com/m/65d8310bfd32ee0/original/KaTeX_SansSerif-Bold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'KaTeX_Main-Regular';
        src: url('https://3plearning.getbynder.com/m/41313fd08db66210/original/KaTeX_Main-Regular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_Main-Italic';
        src: url('https://3plearning.getbynder.com/m/463b27a781ad36a4/original/KaTeX_Main-Italic.woff2') format('woff2');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_Main-Bold';
        src: url('https://3plearning.getbynder.com/m/56d11c0060136f91/original/KaTeX_Main-Bold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'KaTeX_Math-Italic';
        src: url('https://3plearning.getbynder.com/m/676b7ef74beeeb2/original/KaTeX_Math-Italic.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'KaTeX_Main_Original';
        src: url('${mathliveAssetsUrl}/assets/fonts/KaTeX_Main_Original-Regular.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: normal;
    }

    @font-face {
        font-family: 'KaTeX_Main_Original';
        src: url('${mathliveAssetsUrl}/assets/fonts/KaTeX_Main_Original-Italic.woff2') format('woff2');
        font-display: auto;
        font-style: italic;
        font-weight: normal;
    }

    @font-face {
        font-family: 'KaTeX_Main_Original';
        src: url('${mathliveAssetsUrl}/assets/fonts/KaTeX_Main_Original-Bold.woff2') format('woff2');
        font-display: auto;
        font-style: normal;
        font-weight: bold;
    }

    @font-face {
        font-family: 'KaTeX_Main_Original';
        src: url('${mathliveAssetsUrl}/assets/fonts/KaTeX_Main_Original-BoldItalic.woff2') format('woff2');
        font-display: auto;
        font-style: italic;
        font-weight: bold;
    }
`;
