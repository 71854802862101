import { CopyIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import PrimaryButton from '../primaryButton/primary-button';

interface ICopyToClipboardButtonProps {
    label: string;
    altLabel?: string;
    clipboardMessage?: string;
    fontSize?: string;
}

const CopyToClipboardButton: React.FC<ICopyToClipboardButtonProps> = ({ label, altLabel, clipboardMessage, fontSize = 'xs' }) => {
    const toast = useToast();

    return (
        <PrimaryButton
            variant="link"
            color="gray.400"
            _hover={{ color: 'teal.500' }}
            fontSize={fontSize}
            onClick={() => {
                navigator.clipboard.writeText(label);
                toast({
                    title: `Copied ${clipboardMessage ? clipboardMessage : 'GUID'} to clipboard`,
                    isClosable: true,
                    status: 'success',
                    variant: 'subtle',
                    icon: <CopyIcon boxSize={'1.25em'} />,
                    duration: 3000
                });
            }}
        >
            {altLabel ? altLabel : label}
        </PrimaryButton>
    );
};

export default CopyToClipboardButton;
