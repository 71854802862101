import { FetchResult } from '@apollo/client';

import { ITagCreateInput, ITagRemoveInput, TagRelationIdsInput } from './input/tag.interface';
import { TagAddQueries } from './queries/tag.add.queries';
import { TagDeleteQueries } from './queries/tag.delete.queries';
import { TagGetQueries } from './queries/tag.get.queries';
import BaseApiService from '../../../api/base.service';
import { ITag } from '../interfaces/tag-props.interface';

export default class TagService extends BaseApiService {
    constructor() {
        super(window._env_.GATEWAY_API_URL);
    }

    public async getAllTags(): Promise<FetchResult<{ getAllTagsContent: ITag[] }>> {
        return this.graphqlClient.query({
            query: TagGetQueries.getAllTags
        });
    }

    public async getTagsForRecord(recordId: string): Promise<FetchResult<{ getTagsByRelationContent: ITag[] }>> {
        return this.graphqlClient.query({
            query: TagGetQueries.getTagsByRelation,
            variables: { relationId: recordId }
        });
    }

    public async getRelationIdsForTags(input: TagRelationIdsInput): Promise<FetchResult<{ getTagRelationIdsContent: string[] }>> {
        return this.graphqlClient.query({
            query: TagGetQueries.getTagRelationIds,
            variables: { input }
        });
    }

    public async removeTagForRecord(input: ITagRemoveInput): Promise<FetchResult<{ deleteTagContent: boolean }>> {
        return this.graphqlClient.query({
            query: TagDeleteQueries.removeTagForRecord,
            variables: {
                relationId: input.recordId,
                tagId: input.tagId
            }
        });
    }

    public async createTagForRecord(input: ITagCreateInput): Promise<FetchResult<{ createTagContent: ITag }>> {
        return this.graphqlClient.query({
            query: TagAddQueries.createTag,
            variables: {
                data: {
                    relationTypeName: input.recordType,
                    relationId: input.recordId,
                    tagTypeName: input.tagType,
                    value: input.tagValue
                }
            }
        });
    }
}
