import FeatureFlagService from './feature-flags.service';
import { useConquestQuery } from '../hooks/use-conquest-query';

const service = new FeatureFlagService();

export const useGetFeatureFlag = () => {
    return useConquestQuery(async (key: string, fallback: boolean) => {
        const { data } = await service.getFeatureFlag(key, fallback);

        return data?.getFeatureFlag;
    }, 'getFeatureFlag');
};
