import { UseToastOptions } from '@chakra-ui/react';

// Second error message will be used to swap over existing error messages and then eventually
// the first component and be removed
const ServiceErrorMessage2 = (error: string): UseToastOptions => {
    const serviceErrorDisplay = (error: string): UseToastOptions => {
        return {
            position: 'bottom',
            title: 'Service Error',
            description: error,
            status: 'error',
            duration: 5000,
            isClosable: true
        };
    };

    return serviceErrorDisplay(error);
};

export default ServiceErrorMessage2;
