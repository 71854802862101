import DefaultCourseService from './default-course.service';
import { DefaultCourseAddInput, DefaultCoursesInput, DefaultCourseUpdateInput } from './interface';
import { useConquestMutator } from '../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../hooks/use-conquest-query';

const defaultCourseService = new DefaultCourseService();
const getDefaultCourses = 'getDefaultCourses';

export const useGetDefaultCoursesByLocationDeliver = () => {
    return useConquestQuery(async (input: DefaultCoursesInput) => {
        const { data } = await defaultCourseService.getDefaultCoursesByLocationDeliver(input);

        return data?.getDefaultCourseByLocationDeliver;
    }, getDefaultCourses);
};

export const useAddDefaultCourseDeliver = () => {
    return useConquestMutator(async (input: DefaultCourseAddInput) => {
        const { data } = await defaultCourseService.addDefaultCourseDeliver(input);

        return data?.addDefaultCourseDeliver;
    });
};

export const useUpdateDefaultCourseDeliver = () => {
    return useConquestMutator(async (input: DefaultCourseUpdateInput) => {
        const { data } = await defaultCourseService.updateDefaultCourseDeliver(input);

        return data?.updateDefaultCourseByIdDeliver;
    });
};

export const useRemoveDefaultCourseDeliver = () => {
    return useConquestMutator(async (id: number) => {
        const { data } = await defaultCourseService.removeDefaultCourseDeliver(id);

        return data?.removeDefaultCourseDeliver;
    });
};
