import { Spinner } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface LoadingWrapperProps {
    loading: boolean;
}

const LoadingWrapper: React.FC<PropsWithChildren<LoadingWrapperProps>> = ({ loading, children }) => <>{loading ? <Spinner /> : children}</>;

export default LoadingWrapper;
