import { ColumnDef, SortingState } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import DefaultCourseDropdown from './default-course.dropdown';
import { useGetDefaultCoursesByLocationDeliver } from './default-course.service.helper';
import { SelectType } from '../../common/interfaces/types';
import ContentBase from '../base/layout/content.base';
import { TableComponent } from '../base/table';
import CountrySelector from '../countries/country-selector';
import StateSelector from '../countries/state-selector';
import { useGetAllYearGroups } from '../year-group/api/year-group.service.helper';
import { IYearGroupDto } from '../year-group/types/year-group.dto';

const DefaultCourse = () => {
    const { data: yearGroupsList = [] } = useQuery(useGetAllYearGroups()([]));
    const [sorting, setSorting] = useState<SortingState>([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState<SelectType>({ label: 'Australia', value: 'AUS' });
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const { data: defaultCourses = [], refetch: refetchDefaultCourses } = useQuery(
        useGetDefaultCoursesByLocationDeliver()([{ stateCode: selectedState, countryCode: selectedCountryCode.value }])
    );
    const yearGroupColumns = useMemo<ColumnDef<IYearGroupDto>[]>(
        () => [
            {
                accessorKey: 'yearLevel',
                id: 'yearLevel',
                header: 'Year Level',
                enableSorting: false,
                cell: ({ row }) => <div> {row.original.id === 1 ? 'Kindergarten' : `Year ${row.original.yearLevel}`}</div>
            },
            {
                accessorKey: 'courses',
                id: 'courses',
                header: 'Courses',
                enableSorting: false,
                cell: ({ row }) => {
                    const defaultCourse = defaultCourses.find((course) => course.yearGroup.id === row.original.id);

                    if (!row.original.id && !row.original.yearLevel && row.original.yearLevel !== 0) {
                        return <div>None</div>;
                    }

                    return (
                        <DefaultCourseDropdown
                            refetchDefaultCourses={refetchDefaultCourses}
                            id={defaultCourse?.id}
                            courseId={defaultCourse?.courseId}
                            countryCode={selectedCountryCode.value}
                            stateCode={selectedState}
                            yearGroupId={row.original.id!}
                        />
                    );
                }
            }
        ],
        [defaultCourses, refetchDefaultCourses, selectedCountryCode.value, selectedState]
    );

    return (
        <ContentBase header={'Default Course'}>
            <CountrySelector selectedCountry={selectedCountryCode} onChange={setSelectedCountryCode} />
            <StateSelector countryCode={selectedCountryCode.value} stateCode={selectedState} onChange={setSelectedState} />
            <TableComponent columns={yearGroupColumns} data={yearGroupsList} sorting={sorting} setSorting={setSorting} />
        </ContentBase>
    );
};

export default DefaultCourse;
