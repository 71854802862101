import { Box, Heading, HStack, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useMutation } from 'react-query';

import { useValidateAllCatQuestions, useValidateCatQuestions, useValidateCatQuestionVariants } from './api/cat-validator.service.helper';
import { CatQuestionValidationReportDto, CatQuestionValidationReportInput, CatQuestionVariantValidationReportDto } from './types';
import ServiceErrorMessage2 from '../../common/toast-messages/service-error-message-2';
import serviceSuccessMessage from '../../common/toast-messages/service-success-message';
import CsvDownloadButton from '../base/buttons/csv-download-button';
import WarningButton from '../base/buttons/warningButton/warning-button';
import ContentBase from '../base/layout/content.base';

const CatValidator = () => {
    const toast = useToast();
    const [questionValidationData, setQuestionValidationData] = useState<CatQuestionValidationReportDto[]>([]);
    const [questionVariantValidationData, setQuestionVariantValidationData] = useState<CatQuestionVariantValidationReportDto[]>([]);
    const [option, setOption] = useState('au');
    const { mutate: validateCatQuestions } = useMutation(useValidateCatQuestions(), {
        onSuccess: (response) => {
            if (response) {
                setQuestionValidationData(response);
                toast(serviceSuccessMessage(`${response.length} CAT questions validated successfully. CSV is now available for download.`));
            }
        },
        onError: (error) => {
            toast(ServiceErrorMessage2(JSON.stringify(error)));
        }
    });
    const { mutate: validateAllCatQuestions } = useMutation(useValidateAllCatQuestions(), {
        onSuccess: (response) => {
            if (response) {
                setQuestionValidationData(response);
                toast(serviceSuccessMessage(`${response.length} CAT questions validated successfully. CSV is now available for download.`));
            }
        },
        onError: (error) => {
            toast(ServiceErrorMessage2(JSON.stringify(error)));
        }
    });
    const { mutate: validateCatQuestionVariants } = useMutation(useValidateCatQuestionVariants(), {
        onSuccess: (response) => {
            if (response) {
                setQuestionVariantValidationData(response);
                toast(serviceSuccessMessage(`${response.length} CAT question variant(s) validated successfully. CSV is now available for download.`));
            }
        },
        onError: (error) => {
            toast(ServiceErrorMessage2(JSON.stringify(error)));
        }
    });

    return (
        <ContentBase header={'CAT Validator'}>
            <Heading mt={3} fontSize={'xl'}>
                Question Validator
            </Heading>
            <Box pb={4}>Please ensure that you have a valid CSV file encoded in UTF-8.</Box>
            <RadioGroup defaultValue="au" value={option} onChange={setOption}>
                <HStack spacing="24px">
                    <Radio value="au">Validate en-au only</Radio>
                    <Radio value="all">Validate all</Radio>
                </HStack>
            </RadioGroup>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    Papa.parse(acceptedFiles[0], {
                        delimiter: '',
                        chunkSize: 3,
                        header: true,
                        complete(responses) {
                            const returnedData: CatQuestionValidationReportInput[] = responses.data as CatQuestionValidationReportInput[];
                            const validateCatQuestionsInput = returnedData.map((item) => ({
                                Id: item.Id,
                                StudentTitle: item.StudentTitle,
                                TeacherTitle: item.TeacherTitle,
                                CultureCode: item.CultureCode,
                                Link: item.Link
                            }));

                            if (option === 'au') {
                                validateCatQuestions(validateCatQuestionsInput);
                            }

                            if (option === 'all') {
                                validateAllCatQuestions(validateCatQuestionsInput);
                            }
                        }
                    });
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Stack borderWidth="2px" borderStyle="dotted" borderColor="gray.400" p="8" textAlign="center" spacing="1">
                                <FontAwesomeIcon size={'4x'} icon={faFile} />
                                <Heading pt={3} fontSize="lg" color="gray.700" fontWeight="bold">
                                    Drop CSV here
                                </Heading>
                                <Text fontWeight="light">or click to upload</Text>
                            </Stack>
                        </div>
                    </section>
                )}
            </Dropzone>
            <Box pt={4} textAlign={'center'} justifyContent={'center'}>
                <CsvDownloadButton isDisabled={!questionValidationData.length} data={questionValidationData} filename={'cat-question-report'} />
                <WarningButton ml={4} onClick={() => setQuestionValidationData([])} ariaLabel={'reset'}>
                    Reset Question Report
                </WarningButton>
            </Box>
            <Heading mt={3} fontSize={'xl'}>
                Question Variant Validator
            </Heading>
            <Box pb={4}>Please ensure that you have a valid CSV file encoded in UTF-8.</Box>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    Papa.parse(acceptedFiles[0], {
                        delimiter: '',
                        chunkSize: 3,
                        header: true,
                        complete(responses) {
                            const returnedData: CatQuestionValidationReportInput[] = responses.data as CatQuestionValidationReportInput[];
                            const validateCatQuestionsInput = returnedData.map((item) => ({
                                Id: item.Id,
                                StudentTitle: item.StudentTitle,
                                TeacherTitle: item.TeacherTitle,
                                CultureCode: item.CultureCode,
                                Link: item.Link
                            }));

                            validateCatQuestionVariants(validateCatQuestionsInput);
                        }
                    });
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Stack borderWidth="2px" borderStyle="dotted" borderColor="gray.400" p="8" textAlign="center" spacing="1">
                                <FontAwesomeIcon size={'4x'} icon={faFile} />
                                <Heading pt={3} fontSize="lg" color="gray.700" fontWeight="bold">
                                    Drop CSV here
                                </Heading>
                                <Text fontWeight="light">or click to upload</Text>
                            </Stack>
                        </div>
                    </section>
                )}
            </Dropzone>
            <Box pt={4} textAlign={'center'} justifyContent={'center'}>
                <CsvDownloadButton isDisabled={!questionVariantValidationData.length} data={questionVariantValidationData} filename={'cat-question-variant-report'} />
                <WarningButton ml={4} onClick={() => setQuestionVariantValidationData([])} ariaLabel={'reset'}>
                    Reset Question Variant Report
                </WarningButton>
            </Box>
        </ContentBase>
    );
};

export default CatValidator;
