import { Flex, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';

import PrimaryButton from './buttons/primaryButton/primary-button';
import WarningButton from './buttons/warningButton/warning-button';

interface IFormSaveCancelProps {
    cancelButtonText?: string;
    saveButtonText?: string;
    cancelButtonRoute?: string;
    onRecordAdd: (e: React.SyntheticEvent) => void;
}

const FormFooter: React.FC<IFormSaveCancelProps> = ({ cancelButtonRoute, cancelButtonText, onRecordAdd, saveButtonText = 'Save' }) => (
    <Flex mt={4} justifyContent={'flex-end'}>
        <WarningButton ariaLabel={'cancel'} mr={2} variant={'outline'}>
            <Link as={RouteLink} to={cancelButtonRoute ?? '/'} _hover={{ textDecor: 'none' }}>
                {cancelButtonText ?? 'Cancel'}
            </Link>
        </WarningButton>
        <PrimaryButton mr={2} onClick={onRecordAdd} variant={'outline'}>
            {saveButtonText}
        </PrimaryButton>
    </Flex>
);

export default FormFooter;
