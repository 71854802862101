import { ButtonProps, IconButton } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import BaseButton from '../baseButton/base-button';

interface WarningButtonProps extends ButtonProps {
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    ariaLabel: string;
}

const WarningButton: React.FC<PropsWithChildren<WarningButtonProps>> = ({ size = 'md', children, icon, ariaLabel, ...props }) =>
    icon ? (
        <IconButton colorScheme={'red'} size={size} icon={icon} aria-label={ariaLabel || ''} {...props}>
            {children}
        </IconButton>
    ) : (
        <BaseButton colorScheme={'red'} size={size} {...props}>
            {children}
        </BaseButton>
    );

export default WarningButton;
