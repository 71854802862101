import { createContext, useContext } from 'react';

import AnswersStore from '../components/questions/question-store/answers-store';

const store = {
    answersStore: new AnswersStore()
};

export const StoreContext = createContext(store);

export const useStore = () => useContext<typeof store>(StoreContext);

export default store;
