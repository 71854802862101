import { FetchResult } from '@apollo/client';

import { StrandDeliveryQueries } from './strand.delivery.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { AddStrandInput, StrandDto } from '../strand.interface';

export default class StrandDeliveryService extends BaseConquestApiService {
    public async addStrandsDeliver(input: AddStrandInput[]): Promise<FetchResult<{ addStrandsDeliver: StrandDto[] }>> {
        return this.graphqlClient.mutate({
            mutation: StrandDeliveryQueries.addStrandsDeliver,
            variables: { input }
        });
    }

    public async getAllStrands(): Promise<FetchResult<{ getAllStrandsDeliver: StrandDto[] }>> {
        return this.graphqlClient.query({
            query: StrandDeliveryQueries.getAllStrands
        });
    }
}
