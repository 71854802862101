import { Icon, WarningIcon } from '@chakra-ui/icons';
import { Container, Flex, Heading, Link, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

import PrimaryButton from './buttons/primaryButton/primary-button';

const SentryFallback = () => (
    <Flex m={2} p={3} marginTop={'2.5vh'} boxShadow={'0 4px 12px 0 rgba(0, 0, 0, 0.05)'} borderRadius={8} flexDir={'column'} justifyContent={'space-between'} bg={'ghostwhite'}>
        <Container boxShadow={'dark-lg'} p={'6'} rounded={'md'} textAlign={'center'} centerContent>
            <Heading as={'h1'} size={'md'} color={'gray.700'}>
                <Icon as={WarningIcon} color={'red.300'} /> Oops! Something went wrong
            </Heading>
            <VStack>
                <Text>Something unexpected happened. Please let our technical support team know so we can take a look at it!</Text>
                <Link href={'/'} _hover={{ textDecor: 'none' }}>
                    <PrimaryButton size={'lg'}>Return Home</PrimaryButton>
                </Link>
            </VStack>
        </Container>
    </Flex>
);

export default SentryFallback;
