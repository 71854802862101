import { Button, ButtonProps, ResponsiveValue } from '@chakra-ui/react';
import { ThemeTypings } from '@chakra-ui/styled-system';
import React, { forwardRef, PropsWithChildren } from 'react';

interface BaseButtonProps extends ButtonProps {
    colorScheme?: ThemeTypings['colorSchemes'];
    isDisabled?: boolean;
    size?: ResponsiveValue<string | 'sm' | 'md' | 'lg' | 'xs'> | undefined;
    variant?: ResponsiveValue<'link' | 'outline' | string | 'solid' | 'ghost' | 'unstyled'> | undefined;
}

const BaseButton = forwardRef<HTMLButtonElement, PropsWithChildren<BaseButtonProps>>(
    ({ colorScheme = 'teal', variant = 'solid', size = 'md', isDisabled = false, children, ...rest }, ref) => (
        <Button ref={ref} colorScheme={colorScheme} size={size} variant={variant} isDisabled={isDisabled} {...rest}>
            {children}
        </Button>
    )
);

export default BaseButton;
