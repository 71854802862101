import { gql } from '@apollo/client';

export class CourseCountriesQueries {
    static addCourseCountries = gql`
        mutation addCourseCountries($countries: CourseCountryInput!) {
            addCourseCountries(input: $countries) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    countries {
                        countryCode
                    }
                }
            }
        }
    `;

    static removeCourseCountries = gql`
        mutation removeCourseCountries($countries: CourseCountryInput!) {
            removeCourseCountries(input: $countries) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    countries {
                        countryCode
                    }
                }
            }
        }
    `;
}
