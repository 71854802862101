import { gql } from '@apollo/client';

export class CourseUpdateQueries {
    static updateCourseItem = gql`
        mutation update_course_item($courseItem: CourseItemUpdateInput!) {
            updateCourseItem(input: $courseItem) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                        internalNotes
                    }
                }
            }
        }
    `;

    static updateUnitsOfWorkSortOrderInCourse = gql`
        mutation edit_units_of_work_in_course($updatedUnitsOfWorkInCourse: [UnitOfWorkInCourseInput!]!) {
            updateUnitsOfWorkSortOrderInCourse(input: $updatedUnitsOfWorkInCourse) {
                ... on UnitOfWorkInCourseAuthoringDto {
                    id
                    sortOrder
                }
            }
        }
    `;

    static updateCourseYearGroups = gql`
        mutation UpdateCourseYearGroups($input: CourseYearGroupUpdateInput!) {
            updateCourseYearGroups(input: $input) {
                id
            }
        }
    `;

    static updateCourseState = gql`
        mutation updateCourseState($state: CourseStateUpdateInput!) {
            updateCourseState(input: $state) {
                ... on CourseAuthoringDto {
                    id
                    stateCode
                }
            }
        }
    `;
}
