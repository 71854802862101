import { Tooltip, useDisclosure } from '@chakra-ui/react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';

interface FaIconButtonProps extends FontAwesomeIconProps {
    tooltipLabel?: string;
    size?: SizeProp;
    color?: string;
    hoverColor?: string;
}

const FaIconButton: React.FC<FaIconButtonProps> = ({ tooltipLabel, size = 'lg', color = 'auto', hoverColor = '#319795', ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Tooltip label={tooltipLabel}>
            <FontAwesomeIcon
                size={size}
                color={color}
                aria-label={tooltipLabel}
                style={isOpen ? { color: hoverColor } : {}}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                {...props}
            />
        </Tooltip>
    );
};

export default FaIconButton;
