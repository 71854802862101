import { QtcRendererProvider } from '@3plearning/question-components-library';
import { Box, ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import RouteList from './components/base/route-list';
import SentryFallback from './components/base/sentry-fallback';
import { CollapsibleSidebar } from './components/base/sidebar/collapsible-sidebar';
import defaultTheme from './default.theme';
import { fonts } from './fonts/fonts';

const queryClient = new QueryClient();

export const App: React.FC = () => (
    <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider theme={defaultTheme}>
            <Global styles={[fonts]} />
            <Flex>
                <BrowserRouter>
                    <CollapsibleSidebar />
                    <ErrorBoundary fallback={<SentryFallback />}>
                        <Suspense fallback={<Spinner />}>
                            <QtcRendererProvider includeKeypadProvider={false}>
                                <Box flex={'1'} minWidth={0}>
                                    <RouteList />
                                </Box>
                            </QtcRendererProvider>
                        </Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </Flex>
        </ChakraProvider>
    </QueryClientProvider>
);
