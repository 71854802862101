import { FetchResult } from '@apollo/client';

import { CourseCountriesQueries } from './queries/course-countries.queries';
import { CourseRegionsQueries } from './queries/course-regions.queries';
import { CourseAddQueries } from './queries/course.add.queries';
import { CourseChildrenQueries } from './queries/course.children.queries';
import { CourseDeleteQueries } from './queries/course.delete.queries';
import { CourseGetQueries } from './queries/course.get.queries';
import { CourseUpdateQueries } from './queries/course.update.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { RemoveChildFromParentInput } from '../../../common/interfaces/types';
import { IUnitOfWorkInCourse, IUnitOfWorkInCourseAdd } from '../../units-of-work/interfaces/unit-of-work-in-course.interface';
import { ICourseSearch, ICoursesSearch } from '../interfaces/course-search-args.interface';
import { ICourse } from '../interfaces/course.interface';
import { ICourseListState } from '../interfaces/course.list.state.interface';
import { ICourseItemInput } from '../interfaces/input/course-item.input';
import { IContentItemsInCourseAdd } from '../interfaces/input/course.content-item.input.interface';
import { AddUnitOfWorkToCourseInput, ICourseInput } from '../interfaces/input/course.input.interface';
import { ICourseCountryInput, ICourseRegionInput, ICourseStateUpdateInput, ICourseYearGroupUpdateInput } from '../interfaces/input/input';

export default class CourseService extends BaseConquestApiService {
    public async getCourses(searchArgs: ICoursesSearch): Promise<FetchResult<{ getCourses: ICourseListState }>> {
        return this.graphqlClient.query({
            query: CourseGetQueries.getCourses,
            variables: { searchArgs }
        });
    }

    public async getCourse(searchArgs: ICourseSearch): Promise<FetchResult<{ getCourses: ICourseListState }>> {
        return this.graphqlClient.query({
            query: CourseGetQueries.getCourses,
            variables: {
                searchArgs: {
                    courseIds: [searchArgs.id],
                    includeContentItems: searchArgs.includeContentItems,
                    includeUnitsOfWork: searchArgs.includeUnitsOfWork
                }
            }
        });
    }

    public async addCourse(course: ICourseInput): Promise<FetchResult<{ addCourse: ICourse }>> {
        return this.graphqlClient.query({
            query: CourseAddQueries.addCourse,
            variables: { course }
        });
    }

    public async removeCourse(courseId: string): Promise<FetchResult<{ removeCourse: boolean }>> {
        return this.graphqlClient.mutate({
            mutation: CourseDeleteQueries.removeCourse,
            variables: {
                removeCourseId: courseId,
                hardDelete: false
            }
        });
    }

    public async addUnitsOfWorkToCourse(input: AddUnitOfWorkToCourseInput): Promise<FetchResult<{ addUnitsOfWorkToCourse: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseChildrenQueries.addUnitsOfWorkToCourse,
            variables: {
                id: input.id,
                uowInCourse: input.unitsOfWork
            }
        });
    }

    public async removeUnitOfWorkFromCourse(input: RemoveChildFromParentInput): Promise<FetchResult<{ removeUnitsOfWorkFromCourse: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseChildrenQueries.removeUnitsOfWorkFromCourse,
            variables: {
                courseId: input.parentId,
                idsToRemove: input.childIds
            }
        });
    }

    public async updateUnitsOfWorkSortOrderInCourse(input: IUnitOfWorkInCourseAdd[]): Promise<FetchResult<{ updateUnitsOfWorkSortOrderInCourse: IUnitOfWorkInCourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseUpdateQueries.updateUnitsOfWorkSortOrderInCourse,
            variables: { updatedUnitsOfWorkInCourse: input }
        });
    }

    public async addContentItemsToCourse(id: string, contentItems: IContentItemsInCourseAdd[]): Promise<ICourse | Error> {
        const result = await this.graphqlClient.mutate({
            mutation: CourseChildrenQueries.addContentItemsToCourse,
            variables: {
                courseId: id,
                contentItems
            }
        });

        if (!result || result.errors?.length || !result.data) return new Error('Unable to add content item to course');

        return result.data.addContentItemsToCourse;
    }

    public async removeContentItemsFromCourse(input: RemoveChildFromParentInput): Promise<FetchResult<{ removeContentItemsFromCourse: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseChildrenQueries.removeContentItemsFromCourse,
            variables: {
                courseId: input.parentId,
                itemIdsToRemove: input.childIds
            }
        });
    }

    public async addCourseCountries(countries: ICourseCountryInput): Promise<FetchResult<{ addCourseCountries: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseCountriesQueries.addCourseCountries,
            variables: { countries }
        });
    }

    public async removeCourseCountries(countries: ICourseCountryInput): Promise<FetchResult<{ removeCourseCountries: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseCountriesQueries.removeCourseCountries,
            variables: { countries }
        });
    }

    public async addCourseRegions(regions: ICourseRegionInput): Promise<FetchResult<{ addCourseRegions: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseRegionsQueries.addCourseRegions,
            variables: { regions }
        });
    }

    public async removeCourseRegions(regions: ICourseRegionInput): Promise<FetchResult<{ removeCourseRegions: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseRegionsQueries.removeCourseRegions,
            variables: { regions }
        });
    }

    public async addCourseItem(courseItem: ICourseItemInput): Promise<FetchResult<{ addCourseItem: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseAddQueries.addCourseItem,
            variables: { courseItem }
        });
    }

    public async updateCourseItem(courseItem: ICourseItemInput): Promise<FetchResult<{ updateCourseItem: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseUpdateQueries.updateCourseItem,
            variables: { courseItem }
        });
    }

    public async updateCourseYearGroups(input: ICourseYearGroupUpdateInput): Promise<FetchResult<{ updateCourseYearGroups: ICourse }>> {
        return this.graphqlClient.mutate({
            mutation: CourseUpdateQueries.updateCourseYearGroups,
            variables: { input }
        });
    }

    public async removeCourseItem(id: string): Promise<FetchResult<{ removeCourseItem: boolean }>> {
        return this.graphqlClient.mutate({
            mutation: CourseDeleteQueries.deleteCourseItem,
            variables: { id }
        });
    }

    public async updateCourseState(state: ICourseStateUpdateInput) {
        return this.graphqlClient.mutate<{ updateCourseState: ICourse }>({
            mutation: CourseUpdateQueries.updateCourseState,
            variables: { state }
        });
    }
}
