import { gql } from '@apollo/client';

export class StrandQueries {
    static addStrand = gql`
        mutation Mutation($input: StrandAddInputContent!) {
            addStrandContent(input: $input) {
                id
                nodeId
                title
                titleAbbreviation
                masterCourseId
                masterCourseVersion
            }
        }
    `;

    static getStrands = gql`
        query GetStrands($searchArgs: GetStrandsArgs!) {
            getStrands(searchArgs: $searchArgs) {
                finalPage
                limit
                page
                strands {
                    titleAbbreviation
                    title
                    nodeId
                    masterCourseName
                    masterCourseId
                    masterCourseVersion
                    id
                }
                total
            }
        }
    `;

    static removeStrand = gql`
        mutation Mutation($removeStrandContentId: ID!) {
            removeStrandContent(id: $removeStrandContentId)
        }
    `;

    static updateStrand = gql`
        mutation Mutation($input: StrandUpdateInputContent!) {
            updateStrandByIdContent(input: $input) {
                titleAbbreviation
                title
                nodeId
                masterCourseVersion
                masterCourseId
                id
            }
        }
    `;

    static getStrandById = gql`
        query Query($getStrandByIdContentId: String!) {
            getStrandByIdContent(id: $getStrandByIdContentId) {
                titleAbbreviation
                title
                nodeId
                masterCourseVersion
                masterCourseName
                masterCourseId
                id
            }
        }
    `;
}
