import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { IUserDetails } from './interfaces/user-details';

const _keycloakConfig: KeycloakConfig = {
    url: window._env_.KEYCLOAK_AUTH_URL,
    realm: window._env_.KEYCLOAK_REALM ?? '3p-internal-tools',
    clientId: window._env_.KEYCLOAK_CLIENT_ID ?? '3p-conquest'
};
const _keycloak: Keycloak.KeycloakInstance = Keycloak(_keycloakConfig);

let _userDetails: IUserDetails = {} as IUserDetails;

const authentication = async (onAuth: any) => {
    await _keycloak.init({ onLoad: 'login-required' });
    if (!_keycloak) {
        console.log('user is not authenticated');

        return;
    }
    const _user = await _keycloak.loadUserInfo();

    if (_user) {
        _userDetails = _user as IUserDetails;
    }
    onAuth();
};
const isTokenExpired = () => _keycloak.isTokenExpired();
const getToken = () => _keycloak.token;
const updateToken = async () => {
    try {
        await _keycloak.updateToken(5);

        return _keycloak.token;
    } catch {
        _keycloak.login();
    }
};
const hasViewUserAccess = () => {
    const conquestRealmRoles = _keycloak.resourceAccess?.['realm-management']?.roles;
    const hasAccess = conquestRealmRoles?.find((role) => role === 'view-users');

    return !!hasAccess;
};
const getUserDetails = () => _userDetails ?? ({} as IUserDetails);
const hasAdminAccess = () => _keycloak.resourceAccess?.conquest?.roles.find((role) => role === 'Content Administrator');
const hasCatAdminAccess = () => _keycloak.resourceAccess?.conquest?.roles.find((role) => role === 'CAT Admin');
const hasEditCreateAccess = () => {
    const conquestRoles = _keycloak.resourceAccess?.conquest.roles;
    const updateAccess = ['Developer', 'Content Administrator', 'Content Author', 'Content Editor'];
    const hasAccess = conquestRoles?.some((role) => updateAccess.indexOf(role) >= 0);

    return hasAccess;
};
const AuthenticationService = {
    authentication,
    hasViewUserAccess,
    isTokenExpired,
    getUserDetails,
    getToken,
    updateToken,
    hasEditCreateAccess,
    hasAdminAccess,
    hasCatAdminAccess
};

export default AuthenticationService;
