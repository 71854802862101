import { gql } from '@apollo/client';

export class YearGroupQueries {
    static getAllYearGroups = gql`
        query GetAllYearGroups {
            getAllYearGroups {
                yearLevel
                id
            }
        }
    `;
}
