import { useEffect, useState } from 'react';

export interface DefaultColumnInterface {
    description: boolean;
    defaultLocaleId: boolean;
    locales: boolean;
    lastPublished: boolean;
    published: boolean;
    numberOfQuestionVariants: boolean;
    createdOn: boolean;
    teacherTitle: boolean;
    defaultLocale: boolean;
    questionSetsLength: boolean;
    contentItems: boolean;
    questionsLength: boolean;
    contentItemsLength: boolean;
    unitsOfWorkLength: boolean;
    internalNotes: boolean;
    sortOrder: boolean;
    isPublished: boolean;
    questionVariant: boolean;
    questionVariants: boolean;
    outcomeCodes: boolean;
    tags: boolean;
    type: boolean;
}

export const useColumnFilter = (defaultColumnConfig: Partial<DefaultColumnInterface>, relationType: string) => {
    const key = ['conquest', 'table', 'column', 'search', ...relationType.split(' ')].join('-').toLowerCase();
    const [hiddenColumns, setHiddenColumns] = useState<Partial<DefaultColumnInterface>>(defaultColumnConfig);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        if (initialised) {
            localStorage.setItem(key, JSON.stringify(hiddenColumns));
        } else {
            setInitialised(true);
        }
    }, [key, hiddenColumns, initialised]);

    const updateHiddenColumns = (option: { key: string; value: string }) => {
        const { key, value } = option;

        setHiddenColumns((prevHiddenColumns) => ({ ...prevHiddenColumns, [key]: !value }));
    };

    useEffect(() => {
        const storedHiddenColumns = localStorage.getItem(key);

        if (storedHiddenColumns) {
            setHiddenColumns(JSON.parse(storedHiddenColumns));
        }
    }, [key]);

    return {
        hiddenColumns,
        updateHiddenColumns,
        remove: () => {
            setHiddenColumns(defaultColumnConfig);
            localStorage.removeItem(key);
        }
    };
};
