import { ITagCreateInput, ITagRemoveInput, TagRelationIdsInput } from './input/tag.interface';
import TagService from './tags.service';
import { useConquestMutator } from '../../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../../hooks/use-conquest-query';

const getAuthoredTagsKey = 'getAuthoredTags';
const getTagsByRelation = 'getTagsByRelation';
const tagService = new TagService();

export const useGetAllTags = () => {
    return useConquestQuery(async () => {
        const { data } = await tagService.getAllTags();

        return data?.getAllTagsContent;
    }, getAuthoredTagsKey);
};

export const useGetTagsByRelation = () => {
    return useConquestQuery(async (recordId: string) => {
        const { data } = await tagService.getTagsForRecord(recordId);

        return data?.getTagsByRelationContent;
    }, getTagsByRelation);
};

export const useCreateTagForRecord = () => {
    return useConquestMutator(async (input: ITagCreateInput) => {
        const { data } = await tagService.createTagForRecord(input);

        return data?.createTagContent;
    });
};

export const useGetRelationIdsForTagsMutation = () => {
    return useConquestMutator(async (recordId: string) => {
        const { data } = await tagService.getTagsForRecord(recordId);

        return data?.getTagsByRelationContent;
    });
};

export const useGetRelationIdsForTags = () => {
    return useConquestMutator(async (input: TagRelationIdsInput) => {
        const { data } = await tagService.getRelationIdsForTags(input);

        return data?.getTagRelationIdsContent;
    });
};

export const useRemoveTagForRecord = () => {
    return useConquestMutator(async (input: ITagRemoveInput) => {
        const { data } = await tagService.removeTagForRecord(input);

        return data?.deleteTagContent;
    });
};
