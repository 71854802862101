import { FetchResult } from '@apollo/client';

import { CurriculumQueries } from './curriculum.service.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { MasterCourseDto, MasterCourseHierarchyDto, MasterCourseHierarchyGetInput } from '../interface';

export default class CurriculumServices extends BaseConquestApiService {
    public async getMasterCourses(countryCode: string): Promise<FetchResult<{ getMasterCourses: MasterCourseDto[] }>> {
        return this.graphqlClient.query({
            query: CurriculumQueries.getMasterCourses,
            variables: { countryCode }
        });
    }

    public async getMasterCourseHierarchy(input: MasterCourseHierarchyGetInput): Promise<FetchResult<{ getMasterCourseHierarchy: MasterCourseHierarchyDto }>> {
        return this.graphqlClient.query({
            query: CurriculumQueries.getMasterCourseHierarchy,
            variables: { input }
        });
    }
}
