import { Box, ButtonGroup, Flex, Heading, Link, Spacer } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { isProdEnvironment } from '../../../common/constants';
import PrimaryButton from '../buttons/primaryButton/primary-button';
import { BaseProps } from '../interfaces/base.props';

const ContentBase: React.FC<PropsWithChildren<BaseProps>> = ({ addPageRoute, children, header, publishButton, displayLegacyQuestions }) => {
    const addButton = addPageRoute && (
        <Link as={RouteLink} to={addPageRoute} _hover={{ textDecor: 'none' }}>
            <PrimaryButton>Create</PrimaryButton>
        </Link>
    );

    return (
        <Box
            top={'0.5'}
            marginTop={'1.75vh'}
            w={'99%'}
            boxShadow={'0 4px 12px 0 rgba(0, 0, 0, 0.05)'}
            borderRadius={5}
            flexDir={'column'}
            bg={displayLegacyQuestions ? (isProdEnvironment ? '#d4f6ce' : 'lightyellow') : 'ghostwhite'}
        >
            <Box mt={3} p={2}>
                <Flex color={'white'}>
                    <Box>
                        <Heading as={'h4'} size={'md'} color={'gray.500'}>
                            {header}
                        </Heading>
                    </Box>
                    <Spacer />
                    <Box>
                        <ButtonGroup spacing={'3'}>
                            {publishButton}
                            {addButton}
                        </ButtonGroup>
                    </Box>
                </Flex>
                {children}
            </Box>
        </Box>
    );
};

export default ContentBase;
