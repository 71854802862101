import { gql } from '@apollo/client';

export class CountryQueries {
    static getCountry = gql`
        query Query($getRegionId: String!) {
            getRegionContent(id: $getRegionId) {
                createdAt
                id
                isEnabled
                modifiedAt
                name
                parentId
            }
        }
    `;

    static getAllCountries = gql`
        query GetAllCountriesContent {
            getAllCountriesContent {
                name
                code
            }
        }
    `;

    static getStatesForCountry = gql`
        query GetStatesForCountry($code: String!) {
            getCountryContentByCode(code: $code) {
                states {
                    name
                    code
                }
            }
        }
    `;
}
