import { FetchResult } from '@apollo/client';

import { DefaultCourseQueries } from './api/default-course.queries';
import { DefaultCourseAddInput, DefaultCourseDto, DefaultCoursesInput, DefaultCourseUpdateInput } from './interface';
import BaseConquestApiService from '../../api/base-conquest-api.service';

export default class DefaultCourseService extends BaseConquestApiService {
    public async getDefaultCoursesByLocationDeliver(input: DefaultCoursesInput): Promise<FetchResult<{ getDefaultCourseByLocationDeliver: DefaultCourseDto[] }>> {
        return this.graphqlClient.query({
            query: DefaultCourseQueries.getDefaultCoursesByLocationDeliver,
            variables: { input }
        });
    }

    public async addDefaultCourseDeliver(input: DefaultCourseAddInput): Promise<FetchResult<{ addDefaultCourseDeliver: DefaultCourseDto }>> {
        return this.graphqlClient.mutate({
            mutation: DefaultCourseQueries.addDefaultCourseDeliver,
            variables: { input }
        });
    }

    public async updateDefaultCourseDeliver(input: DefaultCourseUpdateInput): Promise<FetchResult<{ updateDefaultCourseByIdDeliver: DefaultCourseDto }>> {
        return this.graphqlClient.mutate({
            mutation: DefaultCourseQueries.updateDefaultCourseDeliver,
            variables: { input }
        });
    }

    public async removeDefaultCourseDeliver(id: number): Promise<FetchResult<{ removeDefaultCourseDeliver: DefaultCourseDto }>> {
        return this.graphqlClient.mutate({
            mutation: DefaultCourseQueries.removeDefaultCourseDeliver,
            variables: { removeDefaultCourseDeliverId: id }
        });
    }
}
