import { FetchResult } from '@apollo/client';

import { CourseDeliveryQueries } from './queries/course.delivery.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { ICoursePublishDto } from '../interfaces/dto/course.publish.dto';
import { CourseArchiveUpdateDto } from '../interfaces/dto/interface';
import { ICourseDeliveryAddInput, ICourseDto } from '../interfaces/input/course.delivery.add.input.interface';
import { CourseArchiveUpdateInput } from '../interfaces/input/input';

export default class CourseDeliveryService extends BaseConquestApiService {
    public async addCourseDeliver(input: ICourseDeliveryAddInput): Promise<FetchResult<{ addCourseDeliver: ICoursePublishDto }>> {
        return this.graphqlClient.mutate({
            mutation: CourseDeliveryQueries.addCourseDeliver,
            variables: { input }
        });
    }
    public async getCourses(): Promise<FetchResult<{ getCourseDeliver: ICourseDto[] }>> {
        return this.graphqlClient.query({
            query: CourseDeliveryQueries.getCourseDeliver
        });
    }

    public async getCourseByIds(ids: string[]): Promise<FetchResult<{ getCoursesByIdsDeliver: ICourseDto[] }>> {
        return this.graphqlClient.query({
            query: CourseDeliveryQueries.getCoursesByIdsDeliver,
            variables: { ids }
        });
    }

    public async updateCourseArchiveStatusDeliver(input: CourseArchiveUpdateInput): Promise<FetchResult<{ updateCourseArchiveStatusDeliver: CourseArchiveUpdateDto }>> {
        return this.graphqlClient.mutate({
            mutation: CourseDeliveryQueries.updateCourseArchiveStatusDeliver,
            variables: { input }
        });
    }
}
