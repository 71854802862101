import CatValidatorService from './cat-validator.service';
import { useConquestMutator } from '../../../hooks/use-conquest-mutator';
import { useConquestQuery } from '../../../hooks/use-conquest-query';
import { CatContentValidationInput, CatQuestionValidationReportInput, CatSearchInput } from '../types';

const catValidatorService = new CatValidatorService();
const getCatQuestionMigratedCountCountKey = 'getCatQuestionMigratedCountCountKey';

export const useGetCatQuestionMigratedCount = () => {
    return useConquestQuery(async () => {
        const { data } = await catValidatorService.getCatQuestionMigratedCount();

        return data?.getCatQuestionMigratedCount;
    }, getCatQuestionMigratedCountCountKey);
};

export const useValidateAllCatQuestions = () => {
    return useConquestMutator(async (input: CatQuestionValidationReportInput[]) => {
        const { data } = await catValidatorService.validateAllCatQuestions(input);

        return data?.validateAllLearnosityQuestions;
    });
};

export const useValidateCatQuestions = () => {
    return useConquestMutator(async (input: CatQuestionValidationReportInput[]) => {
        const { data } = await catValidatorService.validateCatQuestions(input);

        return data?.validateLearnosityQuestions;
    });
};

export const useValidateCatQuestionVariants = () => {
    return useConquestMutator(async (input: CatQuestionValidationReportInput[]) => {
        const { data } = await catValidatorService.validateCatQuestionVariants(input);

        return data?.validateLearnosityQuestionVariants;
    });
};

export const useValidateCatMcqQuestionVariants = () => {
    return useConquestMutator(async (input: CatContentValidationInput[]) => {
        const { data } = await catValidatorService.validateCatMcqQuestionVariants(input);

        return data?.validateLearnosityMcqQuestions;
    });
};

export const useValidateCatDropdownQuestionVariants = () => {
    return useConquestMutator(async (input: CatContentValidationInput[]) => {
        const { data } = await catValidatorService.validateCatDropdownQuestionVariants(input);

        return data?.validateLearnosityDropdownQuestions;
    });
};

export const useValidateCatDnDQuestionVariants = () => {
    return useConquestMutator(async (input: CatContentValidationInput[]) => {
        const { data } = await catValidatorService.validateCatDnDQuestionVariants(input);

        return data?.validateLearnosityDnDQuestions;
    });
};

export const useGetCatQuestionSearch = () => {
    return useConquestMutator(async (input: CatSearchInput) => {
        const { data } = await catValidatorService.getCatQuestionSearch(input);

        return data?.getCatQuestionSearch;
    });
};
