import { gql } from '@apollo/client';

export class TagGetQueries {
    static readonly getTagsByRelation = gql`
        query GetTagsByRelation($relationId: String!) {
            getTagsByRelationContent(relation_id: $relationId) {
                id
                relationId
                relationType
                tagType
                value
            }
        }
    `;

    static readonly getTagRelationIds = gql`
        query getRelatedIdsByTagValues($input: TagsRelationIdsInput!) {
            getTagRelationIdsContent(input: $input)
        }
    `;

    static readonly getAllTags = gql`
        query getAllTagsContent {
            getAllTagsContent {
                id
                relationId
                relationType
                tagType
                value
            }
        }
    `;
}
