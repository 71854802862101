import CurriculumServices from './curriculum.service';
import { useConquestMutator } from '../../../hooks/use-conquest-mutator';
import { MasterCourseHierarchyGetInput } from '../interface';

const curriculumService = new CurriculumServices();

export const useGetMasterCourses = () => {
    return useConquestMutator(async (countryCode: string) => {
        const { data } = await curriculumService.getMasterCourses(countryCode);

        return data?.getMasterCourses;
    });
};

export const useGetMasterCourseHierarchy = () => {
    return useConquestMutator(async (input: MasterCourseHierarchyGetInput) => {
        const { data } = await curriculumService.getMasterCourseHierarchy(input);

        return data?.getMasterCourseHierarchy;
    });
};
