import { gql } from '@apollo/client';

export class CurriculumQueries {
    static getMasterCourses = gql`
        query GetMasterCourses($countryCode: String!) {
            getMasterCourses(countryCode: $countryCode) {
                accessStatus
                alias
                countryCode
                courseName
                createdDate
                documentId
                masterCourseId
                publicationId
                state
                subjectId
                version
                yearGroups
            }
        }
    `;

    static getMasterCourseHierarchy = gql`
        query GetMasterCourseHierarchy($input: MasterCourseHierarchyGetInput!) {
            getMasterCourseHierarchy(input: $input) {
                publicationId
                masterCourseId
                documentId
                standardTree {
                    label
                    id
                    description
                    code
                    childNodes {
                        code
                        description
                        id
                        label
                        childNodes {
                            label
                            id
                            description
                            code
                            childNodes {
                                label
                                id
                                description
                                code
                            }
                        }
                    }
                }
            }
        }
    `;
}
