import { AnswersProps } from './answers-store.types';

export const findAnswerIndex = (id: number, setIndex: number, answers: AnswersProps) => {
    let existingIndex = null;

    answers[setIndex].find((i, index) => {
        existingIndex = index;

        return i.id === id;
    });

    return existingIndex;
}
