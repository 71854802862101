import React from 'react';

interface TableDateColumn {
    date: string;
}

export const TableDateColumn: React.FC<TableDateColumn> = ({ date }) => {
    const convertedTime = new Date(date).toLocaleString('en-au');

    return <>{convertedTime}</>;
};
