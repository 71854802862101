import * as yup from 'yup';

import { isTwoCharacterAlphaNumericStrandAbbreviation } from '../../../common/regex';
import { ValidationConstants } from '../../../common/validation-constants';

const StrandCreateValidationSchema = yup.object().shape({
    title: yup.string().max(ValidationConstants.titleMaximumLength, ValidationConstants.titleMaximumValidationMessage).required(ValidationConstants.titleRequiredValidationMessage),
    titleAbbreviation: yup
        .string()
        .matches(isTwoCharacterAlphaNumericStrandAbbreviation, ValidationConstants.strandAbbreviationMaximumValidationMessage)
        .uppercase()
        .max(ValidationConstants.strandAbbreviationMaximumLength, ValidationConstants.strandAbbreviationMaximumValidationMessage)
        .required(),
    masterCourseId: yup.string().uuid().required(),
    masterCourseVersion: yup.number().required(),
    masterCourseName: yup.string(),
    nodeId: yup.string().uuid().required()
});

export default StrandCreateValidationSchema;
