import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { DragDropContext, Draggable, DragUpdate, Droppable } from '@hello-pangea/dnd';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { getRowStyle, getTableStyle } from './table-helper';

interface TableProps<T extends object> {
    columns: ColumnDef<T>[];
    data: T[];
    isDragDropEnabled?: boolean;
    hiddenColumns?: { [key: string]: boolean };
    onDragDrop?: (input: T[]) => void;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

export const TableComponent = <TData extends object>({ sorting, setSorting, columns, data, isDragDropEnabled = false, onDragDrop, hiddenColumns }: TableProps<TData>) => {
    const table = useReactTable({
        data,
        columns,
        manualSorting: true,
        state: {
            sorting,
            columnVisibility: hiddenColumns
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting
    });
    const handleOnDragEnd = (result: DragUpdate) => {
        const newRows = Array.from(data);
        const [reorderedItem] = newRows.splice(result.source.index, 1);

        if (!result.destination) return;
        newRows.splice(result.destination.index, 0, reorderedItem);

        onDragDrop && onDragDrop(newRows);
    };

    return (
        <>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable isDropDisabled={!isDragDropEnabled} droppableId={'droppable-table'}>
                    {(provided, snapshot) => (
                        <TableContainer>
                            <Table variant={'simple'} style={getTableStyle(snapshot.isDraggingOver)} overflowX="scroll">
                                <Thead data-testid={'table-header'}>
                                    {table.getHeaderGroups().map((headerGroup) => {
                                        return (
                                            <Tr key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <Th key={header.id} id={header.id} colSpan={header.colSpan}>
                                                            {header.isPlaceholder ? null : (
                                                                <div
                                                                    aria-hidden={'true'}
                                                                    id={header.id}
                                                                    {...(header.column.getCanSort() && {
                                                                        className: 'cursor-pointer select-none',
                                                                        onClick: (e) => header.column.toggleSorting(header.column.getIsSorted() === 'asc', e.shiftKey)
                                                                    })}
                                                                >
                                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    {header.column.getCanSort() &&
                                                                        ({
                                                                            asc: <Icon data-testid={'arrow-down'} as={ArrowDownIcon} />,
                                                                            desc: <Icon data-testid={'arrow-up'} as={ArrowUpIcon} />
                                                                        }[header.column.getIsSorted() as string] ?? <Icon as={ArrowUpDownIcon} />)}
                                                                </div>
                                                            )}
                                                        </Th>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Thead>
                                <Tbody {...provided.droppableProps} ref={provided.innerRef}>
                                    {table.getRowModel().rows.map((row) => {
                                        return (
                                            <Draggable isDragDisabled={!isDragDropEnabled} key={row.id} draggableId={row.id} index={Number(row.id)}>
                                                {(provided, snapshot) => (
                                                    <Tr
                                                        key={row.id}
                                                        id={row.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getRowStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        {row.getVisibleCells().map((cell) => {
                                                            return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
                                                        })}
                                                    </Tr>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};
