import { useCallback, useState } from 'react';
import { Point } from 'react-d3-tree';

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 } as Point) => {
    const [translate, setTranslate] = useState<Point>(defaultTranslate);
    const containerRef = useCallback((containerElem: HTMLDivElement | null) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();

            setTranslate({ x: width / 2, y: height / 5 });
        }
    }, []);

    return {
        translate,
        containerRef
    };
};
