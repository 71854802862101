import { Menu, MenuButton, MenuList, MenuGroup, CheckboxGroup, Checkbox, Stack, StackDivider, Button, Flex, Text } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface TableColumnFilterMenuProps {
    menuOptions: object;
    restoreDefaultConfig: () => void;
    updateMenu: (option: { key: string; value: string }) => void;
}

export const TableColumnFilterMenu: React.FC<TableColumnFilterMenuProps> = ({ menuOptions, updateMenu, restoreDefaultConfig }) => {
    const [defaultsHovered, setDefaultsHovered] = useState(false);
    const updatedColumnOptions: { key: string; value: string }[] = [];

    for (const [key, value] of Object.entries(menuOptions)) {
        updatedColumnOptions.push({ key, value });
    }

    return (
        <Flex justify={'flex-end'} p={1}>
            <Menu closeOnSelect={false}>
                {({ isOpen }) => (
                    <>
                        <MenuButton
                            as={Button}
                            fontSize="lg"
                            variant="ghost"
                            colorScheme="teal"
                            icon={<FontAwesomeIcon icon={faFilter} />}
                            rightIcon={<FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />}
                        >
                            Columns
                        </MenuButton>
                        <MenuList style={{ border: '2px solid teal', borderRadius: 0 }}>
                            <MenuGroup title="Table Filter" fontSize="lg" mb={0}>
                                <Text
                                    p={5}
                                    pt={2}
                                    pb={2}
                                    cursor="pointer"
                                    color={defaultsHovered ? 'teal' : 'gray'}
                                    onClick={restoreDefaultConfig}
                                    onMouseEnter={() => setDefaultsHovered(true)}
                                    onMouseLeave={() => setDefaultsHovered(false)}
                                >
                                    Restore defaults
                                </Text>
                                <CheckboxGroup colorScheme="teal">
                                    <Stack p={5} pt={1} divider={<StackDivider />}>
                                        {updatedColumnOptions.map((option) => {
                                            const formattedTitle = option.key.replace(/([A-Z])/g, ' $1').trim();

                                            return (
                                                <Checkbox key={option.key} textTransform="capitalize" isChecked={!!option.value} onChange={() => updateMenu(option)}>
                                                    {formattedTitle}
                                                </Checkbox>
                                            );
                                        })}
                                    </Stack>
                                </CheckboxGroup>
                            </MenuGroup>
                        </MenuList>
                    </>
                )}
            </Menu>
        </Flex>
    );
};
