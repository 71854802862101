import { useQuery } from 'react-query';

import { useGetFeatureFlag } from './feature-flags.service.helper';

export const useFeatureFlag = (key: string, fallback = false) => {
    const {
        data: flagValue,
        isLoading,
        error
    } = useQuery(
        useGetFeatureFlag()([key, fallback], {
            staleTime: 25 * (60 * 1000),
            cacheTime: 30 * (60 * 1000)
        })
    );

    if (isLoading || error || flagValue === undefined) return fallback;

    return flagValue;
};
