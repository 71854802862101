import { InputboxProps, ThemeProps } from '@3plearning/question-components-library';

import { IQuestionSet } from '../components/question-sets/interfaces/question-set.interface';

export const contentItemName = 'Content Items';

export const contentItemsName = 'Create Content ';

export const createContentItemsName = 'Create Content Item';

export const contentItemsDetailsName = 'Content Item Details';

export const addContentMediaName = 'Create Content Media';

export const editContentMediaName = 'Edit Content Media';

export const contentMediaDetails = 'Content Media Details';

export const baseContentItemsUrl = '/content-items';

export const detailContentItemUrl = (id: string) => `${baseContentItemsUrl}/${id}`;

export const detailContentItemWithParentUrl = (id: string, parentId: string, parentName: string, parentBaseUrl: string) =>
    `${detailContentItemUrl(id)}?parentId=${parentId}&parentName=${parentName}&parentBaseUrl=${parentBaseUrl}`;

export const addContentItem = '/content-items/add';

export const detailContentMediaUrl = (id: string, itemId: string) => `${baseContentItemsUrl}/${itemId}/content-media/${id}`;

export const addContentMediaUrl = (itemId: string) => `${baseContentItemsUrl}/${itemId}/content-media/add`;

export const editContentMediaUrl = (id: string, itemId: string) => `${baseContentItemsUrl}/${itemId}/content-media/edit/${id}`;

export const addContentItemWithCourse = (parentId: string) => `/courses/${parentId}/content-items/add`;

export const addContentItemWithUnitOfWork = (parentId: string) => `/units-of-work/${parentId}/content-items/add`;

export const addContentItemWithQuestionSet = (parentId: string) => `/question-sets/${parentId}/content-items/add`;

export const courseName = 'Course Details';

export const coursesName = 'Course';

export const createCoursesName = 'Create Course';

export const baseCourseUrl = '/courses';

export const baseCourseUrlAsParent = 'courses';

export const addCourseUrl = '/courses/add';

export const detailCourseUrl = (id: string) => `${baseCourseUrl}/${id}`;

export const addUnitOfWorkToCourse = 'Add Unit of Work To This Course';

export const createUnitOfWorkToCourse = 'Create New Unit of Work For Course';

export const createContentItemToCourse = 'Create Content Item For This Course';

export const addContentItemToCourse = 'Add Content Item To This Course';

export const addQuestionSetToCourse = 'Add Question Set To Unit of Work';

export const linkCourseWithUnitOfWorkUrl = (parentId: string, sortOrderId: number) => `${baseCourseUrl}/${parentId}/units-of-work/link/${sortOrderId}`;

export const linkCourseWithContentItemUrl = (parentId: string) => `${baseCourseUrl}/${parentId}/content-items/link`;

export const addStrandUrl = '/strand/add';

export const baseStrandUrl = '/strand';

export const editStrandUrl = (id: string) => `${baseStrandUrl}/edit/${id}`;

export const baseOutcomeCodeUrl = '/outcome-code';

export const addOutcomeCodeUrl = `${baseOutcomeCodeUrl}/add`;

export const editOutcomeCodeUrl = (id: string) => `${baseOutcomeCodeUrl}/edit/${id}`;

export const unitOfWorkName = 'Unit Of Work Details';

export const unitsOfWorkName = 'Units Of Work';

export const baseUnitOfWorkUrl = '/units-of-work';

export const baseUnitOfWorkUrlAsParent = 'units-of-work';

export const addUnitOfWorkUrl = '/units-of-work/add';

export const createUnitOfWork = 'Create Unit Of Work';

export const addContentItemToUnitOfWork = 'Add Content Item To Unit Of Work';

export const createQuestionToUnitOfWork = 'Create Question Set For Unit Of Work';

export const addQuestionSetToUnitOfWork = 'Add Question Set To Unit Of Work';

export const createContentItemToUnitOfWork = 'Create Content Item For Unit Of Work';

export const addUnitOfWorkWithParentUrl = (parentId: string) => `${baseCourseUrl}/${parentId}/units-of-work/add`;

export const detailUnitOfWorkUrl = (id: string) => `${baseUnitOfWorkUrl}/${id}`;

export const detailUnitOfWorkWithParentUrl = (id: string, parentId: string) => `${baseUnitOfWorkUrl}/${id}?parentId=${parentId}`;

export const linkUnitOfWorkWithQuestionSetUrl = (parentId: string, sortOrderId: number) => `${baseUnitOfWorkUrl}/${parentId}/question-sets/link/${sortOrderId}`;

export const linkUnitOfWorkWithContentItemUrl = (parentId: string) => `${baseUnitOfWorkUrl}/${parentId}/content-items/link`;

export const questionSetName = 'Question Set Details';

export const questionSetsName = 'Question Sets';

export const createQuestionSetsName = 'Create Question Set';

export const addQuestionToQuestionSetsName = 'Add Question To Question Set';

export const addContentToQuestionSetsName = 'Add Content Items To Question Set';

export const createContentItemForQuestionSetsName = 'Create Content Item For Question Set';

export const createQuestionForQuestionSetsName = 'Create Question For Question Set';

export const baseQuestionSetUrl = '/question-sets';

export const baseQuestionSetUrlAsParent = 'question-sets';

export const addQuestionSetUrl = '/question-sets/add';

export const addQuestionSetWithParentUrl = (parentId: string, sortOrderId: number) => `${baseUnitOfWorkUrl}/${parentId}/question-sets/add/${sortOrderId}`;

export const detailQuestionSetUrl = (id: string) => `${baseQuestionSetUrl}/${id}`;

export const detailQuestionSetWithParentUrl = (id: string, parentId: string) => `${baseQuestionSetUrl}/${id}?parentId=${parentId}`;

export const linkQuestionSetWithQuestionUrl = (parentId: string, sortOrderId: number) => `${baseQuestionSetUrl}/${parentId}/questions/link/${sortOrderId}`;

export const linkQuestionSetWithContentItemUrl = (parentId: string) => `${baseQuestionSetUrl}/${parentId}/content-items/link`;

export const questionName = 'Question Details';

export const questionsName = 'Questions';

export const addQuestionsName = 'Create Question';

export const addQuestionsVariant = 'Create Question Variant';

export const baseQuestionUrl = '/questions';

export const baseCatQuestionUrl = '/cat-questions';

export const addQuestionUrl = '/questions/add';

export const editQuestion = 'Edit Question';

export const editQuestionVariant = 'Edit Question Variant';

export const questionSetTypes = {
    0: 'Question Set',
    1: 'Diagnostic Test',
    2: 'Unit Test'
};

export const getKeyboardPresetOptions = (includesNone = false): Record<number, string> => {
    const options: Record<number, string> = {
        0: 'Simple (Numerals)',
        1: 'Numerals & Decimals',
        2: 'Numerals & Operators',
        3: 'Numerics & Symbols',
        4: 'Qwerty',
        5: 'Basic',
        6: 'Basic junior',
        7: 'Algebra',
        8: 'Trigonometry',
        9: 'General',
        10: 'Custom'
    };

    if (includesNone) {
        options[-1] = 'None';
    }

    return options;
};

export const arrayOfKeyboardPresetOptions = Object.entries(getKeyboardPresetOptions(true)).map(([value, label]) => ({
    label,
    id: value
}));

export const addQuestionWithParentUrl = (parentId: string, sortOrderId: number, questionSetType: IQuestionSet['type']) =>
    `/questions/${parentId}/questions/add/${sortOrderId}/type/${questionSetType}`;

export const detailQuestionUrl = (id: string) => `${baseQuestionUrl}/${id}`;

export const detailCatQuestionUrl = (id: string) => `${baseCatQuestionUrl}/${id}`;

export const detailQuestionWithParentUrl = (id: string, parentId: string) => `${baseQuestionUrl}/${id}?parentId=${parentId}`;

export const editQuestionUrl = (id: string, displayLegacyQuestions: boolean) => `${displayLegacyQuestions ? baseCatQuestionUrl : baseQuestionUrl}/edit/${id}`;

export const editQuestionUrlWithParent = (id: string, parentId: string) => `${baseQuestionUrl}/edit/${id}?parentId=${parentId}`;

export const questionVariantName = 'Question Variant Details';

export const detailCatQuestionVariantUrl = (id: string, parentId: string) => `/cat-questions/${parentId}/variants/${id}`;

export const detailQuestionVariantUrl = (id: string, parentId: string) => `/questions/${parentId}/variants/${id}`;

export const editQuestionVariantUrl = (id: string, parentId: string) => `/questions/${parentId}/variants/edit/${id}`;

export const editCatQuestionVariantUrl = (id: string, parentId: string) => `/cat-questions/${parentId}/variants/edit/${id}`;

export const addQuestionVariantUrl = (parentId: string, sortOrderId: number) => `/questions/${parentId}/variants/add/${sortOrderId}`;

export const mathliveAssetsUrl = 'https://cdn.questicon.mathletics.com/assets/sansserifpartial';

export const DEFAULT_LIST_LIMIT = 20;

export const getInlineTextColours = ({ colors }: ThemeProps) => {
    return [
        {
            colour: colors?.neutral[900],
            category: 'do',
            label: 'Black',
            ringColour: 'gray'
        },
        {
            colour: colors?.primary[800],
            category: 'think',
            label: 'Blue',
            ringColour: 'blue'
        },
        {
            colour: colors?.green[800],
            category: 'answer',
            label: 'Green',
            ringColour: 'green'
        },
        {
            colour: colors?.orchid[700],
            category: 'plan',
            label: 'Magenta',
            ringColour: 'purple'
        }
    ];
};

export const getHighlightColours = ({ colors }: ThemeProps) => {
    return [
        {
            colour: colors?.blue['300'],
            iconColour: colors?.primary[800],
            category: 'secondary',
            label: 'Blue',
            ringColour: 'blue'
        },
        {
            colour: colors?.green['500'],
            iconColour: colors?.green[800],
            category: 'primary',
            label: 'Green',
            ringColour: 'green'
        },
        {
            colour: colors?.orchid['100'],
            iconColour: colors?.orchid[700],
            category: 'quaternary',
            label: 'Magenta',
            ringColour: 'purple'
        },
        {
            colour: colors?.yellow['200'],
            iconColour: colors?.yellow[600],
            category: 'tertiary',
            label: 'Yellow',
            ringColour: 'yellow'
        }
    ];
};

export const questionSetType = 0;

export const diagnosticTest = 1;

export const unitTest = 2;

export const isProdEnvironment = window._env_.ENVIRONMENT === 'prod';

export const isQAEnvironment = window._env_.ENVIRONMENT === 'qa';

export const defaultInputboxOptions: Partial<InputboxProps> = {
    hasKeypadToggle: true,
    onInit: (mfe) => {
        const setKeypadLayout = () => {
            window.mathVirtualKeyboard.layouts = 'default';
        };

        mfe.addEventListener('focus', setKeypadLayout);
    }
};

export const TableBorderOptions = {
    None: 0,
    All: 1,
    'Exclude Outer': 2
} as const;

export const TableCellAlignmentOptions = {
    left: 0,
    center: 1,
    right: 2
} as const;

export const VerticalTableCellAlignmentOptions = {
    top: 0,
    center: 1,
    bottom: 2
} as const;

export const TableCellFlowchartArrowOptions = {
    none: 0,
    'upward left': 1,
    'upward right': 2,
    'forward top': 3,
    'forward bottom': 4,
    'downward left': 5,
    'downward right': 6,
    'backward top': 7,
    'backward bottom': 8
} as const;

export const fileExtension = (url: string) => {
    const imageFormatLength = 3;

    return url?.substring(url.length - imageFormatLength);
};

export const isSvg = (url: string) => {
    return fileExtension(url) === 'svg';
};

export const DefaultTextColor = '#4a5568';

export const infiniteStaleTime = 10000000000;
