import { FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';

import { useGetAllCountries } from './api/country.service.helper';
import { SelectType } from '../../common/interfaces/types';

interface CountrySelector {
    selectedCountry?: SelectType;
    onChange: (selectedCountry: SelectType) => void;
    isRequired?: boolean;
}

const CountrySelector: React.FC<CountrySelector> = ({ selectedCountry, onChange, isRequired = false }) => {
    const { data: countriesList = [] } = useQuery(useGetAllCountries()([]));
    const countryOptions = countriesList.map((country) => ({
        value: country.code,
        label: country.name
    }));
    const idSelector = 'country-selector';

    return (
        <FormControl pt={3} isRequired={isRequired}>
            <FormLabel htmlFor={idSelector} mt={4}>
                Country
            </FormLabel>
            <Select
                isDisabled={!countriesList.length}
                id={idSelector}
                aria-label={'Select country'}
                value={selectedCountry}
                options={countryOptions}
                onChange={(value) => onChange(value!)}
                placeholder="Select country"
                styles={{
                    control: (base) => ({
                        ...base,
                        backgroundColor: 'transparent',
                        borderColor: 'inherit'
                    })
                }}
            />
        </FormControl>
    );
};

export default CountrySelector;
