import { gql } from '@apollo/client';

export class CourseDeliveryQueries {
    static addCourseDeliver = gql`
        mutation addCourseDeliver($input: CourseAddInputDeliver!) {
            addCourseDeliver(input: $input) {
                id
            }
        }
    `;

    static getCourseDeliver = gql`
        query GetCourseDeliver {
            getCourseDeliver {
                id
                isArchived
                updatedOn
                courseItems {
                    title
                }
            }
        }
    `;

    static getCoursesByIdsDeliver = gql`
        query getCoursesByIdsDeliver($ids: [ID!]!) {
            getCoursesByIdsDeliver(ids: $ids) {
                id
                isArchived
                updatedOn
                courseItems {
                    title
                }
            }
        }
    `;

    static updateCourseArchiveStatusDeliver = gql`
        mutation UpdateCourseArchiveStatusDeliver($input: CourseArchiveUpdateInputDeliver!) {
            updateCourseArchiveStatusDeliver(input: $input) {
                id
                isArchived
            }
        }
    `;
}
