import { gql } from '@apollo/client';

export class DataDictionaryGetQueries {
    static readonly getDataDictionaryEntry = gql`
        query GetDataDictionaryEntry($entryId: Float!) {
            getDataDictionaryEntryContent(id: $entryId) {
                category
                id
                sortOrder
                value
            }
        }
    `;

    static readonly getAllDataDictionaryEntries = gql`
        query GetAllDataDictionaryEntries($limit: Float!, $page: Float!) {
            getAllDataDictionaryEntriesContent(limit: $limit, page: $page) {
                finalPage
                limit
                page
                total
                entries {
                    category
                    id
                    sortOrder
                    value
                }
            }
        }
    `;

    static readonly getDataDictionaryEntriesByCategory = gql`
        query GetDataDictionaryEntriesByCategory($category: String!, $limit: Float!, $page: Float!) {
            getDataDictionaryEntriesByCategoryContent(category: $category, limit: $limit, page: $page) {
                limit
                page
                entries {
                    id
                    sortOrder
                    category
                    value
                }
            }
        }
    `;
}
