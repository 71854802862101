import { ButtonProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import BaseButton from '../baseButton/base-button';

const PrimaryButton: React.FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => (
    <BaseButton colorScheme={'teal'} size={'md'} {...props}>
        {children}
    </BaseButton>
);

export default PrimaryButton;
