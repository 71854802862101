import { gql } from '@apollo/client';

export class StrandDeliveryQueries {
    static addStrandsDeliver = gql`
        mutation AddStrandsDeliver($input: [StrandAddInputDeliver!]!) {
            addStrandsDeliver(input: $input) {
                id
                nodeId
                title
                titleAbbreviation
                masterCourseName
                masterCourseVersion
                masterCourseId
            }
        }
    `;

    static getAllStrands = gql`
        query GetAllStrandsDeliver {
            getAllStrandsDeliver {
                titleAbbreviation
                title
                nodeId
                masterCourseName
                masterCourseVersion
                masterCourseId
                id
            }
        }
    `;
}
