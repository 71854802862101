import { FetchResult } from '@apollo/client';

import { CatValidatorQueries } from './queries/cat-validator.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import {
    CatMcqValidationDto,
    CatContentValidationInput,
    CatQuestionValidationReportDto,
    CatQuestionValidationReportInput,
    CatQuestionVariantValidationReportDto,
    CatSearchInput,
    GetCatQuestionSearchDto,
    CatDropdownValidationDto,
    CatDnDValidationDto
} from '../types';

export default class CatValidatorService extends BaseConquestApiService {
    public async validateAllCatQuestions(input: CatQuestionValidationReportInput[]): Promise<FetchResult<{ validateAllLearnosityQuestions: CatQuestionValidationReportDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateAllCatQuestions,
            variables: { input }
        });
    }

    public async getCatQuestionMigratedCount(): Promise<
        FetchResult<{
            getCatQuestionMigratedCount: {
                type: string;
                count: string;
                questionCount: string;
            }[];
        }>
    > {
        return this.graphqlClient.query({
            query: CatValidatorQueries.getCatQuestionMigratedCount
        });
    }

    public async validateCatQuestions(input: CatQuestionValidationReportInput[]): Promise<FetchResult<{ validateLearnosityQuestions: CatQuestionValidationReportDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateCatQuestions,
            variables: { input }
        });
    }

    public async validateCatQuestionVariants(
        input: CatQuestionValidationReportInput[]
    ): Promise<FetchResult<{ validateLearnosityQuestionVariants: CatQuestionVariantValidationReportDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateCatQuestionVariants,
            variables: { input }
        });
    }

    public async validateCatMcqQuestionVariants(input: CatContentValidationInput[]): Promise<FetchResult<{ validateLearnosityMcqQuestions: CatMcqValidationDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateCatMcqQuestions,
            variables: { input }
        });
    }

    public async validateCatDropdownQuestionVariants(
        input: CatContentValidationInput[]
    ): Promise<FetchResult<{ validateLearnosityDropdownQuestions: CatDropdownValidationDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateCatDropdownQuestions,
            variables: { input }
        });
    }

    public async validateCatDnDQuestionVariants(input: CatContentValidationInput[]): Promise<FetchResult<{ validateLearnosityDnDQuestions: CatDnDValidationDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.validateCatDnDQuestions,
            variables: { input }
        });
    }

    public async getCatQuestionSearch(input: CatSearchInput): Promise<FetchResult<{ getCatQuestionSearch: GetCatQuestionSearchDto[] }>> {
        return this.graphqlClient.query({
            query: CatValidatorQueries.getCatQuestionSearch,
            variables: { input }
        });
    }
}
