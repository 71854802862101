import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, ModalContentProps } from '@chakra-ui/react';
import React, { PropsWithChildren, ReactNode } from 'react';

import PrimaryButton from '../buttons/primaryButton/primary-button';
import SecondaryButton from '../buttons/secondaryButton/secondary-button';

export interface IModalComponent extends ModalProps {
    onClose: () => void;
    onAction?: () => void;
    onSecondaryAction?: () => void;
    isOpen: boolean;
    header: ReactNode;
    actionText?: string;
    secondaryActionText?: string;
    modalOptions?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>;
    modalContentOptions?: ModalContentProps;
    isActionDisabled?: boolean;
    hideFooter?: boolean;
}

const ModalComponent: React.FC<PropsWithChildren<IModalComponent>> = ({
    onClose,
    onAction,
    onSecondaryAction = onClose,
    isOpen,
    header,
    actionText = 'Save',
    secondaryActionText = 'Cancel',
    children,
    modalContentOptions,
    hideFooter = false,
    isActionDisabled = false,
    ...props
}) => (
    <Modal trapFocus={false} isOpen={isOpen} onClose={onClose} size={props.modalOptions?.size} {...props}>
        <ModalOverlay />
        <ModalContent {...modalContentOptions}>
            <ModalHeader pb={'0'}>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{children}</ModalBody>
            {!hideFooter && (
                <ModalFooter pt={0}>
                    <SecondaryButton ariaLabel={'secondary-button'} onClick={onSecondaryAction}>
                        {secondaryActionText}
                    </SecondaryButton>
                    {onAction && (
                        <PrimaryButton
                            isDisabled={isActionDisabled}
                            onClick={async () => {
                                await onAction();
                                onClose();
                            }}
                            ml={3}
                        >
                            {actionText}
                        </PrimaryButton>
                    )}
                </ModalFooter>
            )}
        </ModalContent>
    </Modal>
);

export default ModalComponent;
