import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Center, IconButton, useDisclosure, Collapse } from '@chakra-ui/react';
import React from 'react';

import Sidebar from './sidebar';

export const CollapsibleSidebar = () => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

    return (
        <Center w={isOpen ? '238px' : '50px'} position="relative">
            <IconButton
                aria-label="Collapse sidebar"
                icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                onClick={onToggle}
                size="md"
                variant="outline"
                position="absolute"
                top="16px"
                m="5px"
                zIndex="1"
                {...(isOpen ? { right: '10px' } : { left: 0 })}
            />
            <Collapse in={isOpen} animateOpacity>
                <Sidebar />
            </Collapse>
        </Center>
    );
};
