import { UseQueryOptions } from 'react-query';

export const ReactQueryDefaultConfig = {
    refetchOnWindowFocus: false
};

export type ReactQueryError = {
    message: string
};

const getReactQueryConfig = <TResult, Variables extends any[]>(
    queryFn: (...args: Variables) => () => TResult | Promise<TResult>,
    contentKey: string,
    endpointConfig?: UseQueryOptions<TResult, ReactQueryError>,
    ...additionalKeys: string[]
) => (args: Variables, config?: UseQueryOptions<TResult, ReactQueryError>) => ({
        queryKey: [contentKey, ...additionalKeys, ...args],
        queryFn: queryFn(...args),
        ...ReactQueryDefaultConfig,
        ...endpointConfig,
        ...config
    });

export const useConquestQuery = <TResult, Variables extends any[]>(
    queryFn: (...args: Variables) => TResult | Promise<TResult>,
    contentKey: string,
    endpointConfig?: UseQueryOptions<TResult, ReactQueryError>,
    ...additionalKeys: string[]
) => {
    return getReactQueryConfig<TResult, Variables>(
        (...args) => () => queryFn(...args),
        contentKey,
        endpointConfig,
        ...additionalKeys
    );
};

