import { useDisclosure, Box, Heading } from '@chakra-ui/react';
import { faShieldCat } from '@fortawesome/pro-solid-svg-icons';
import { toJS } from 'mobx';
import React from 'react';
import ReactJson from 'react-json-view';
import { useQuery } from 'react-query';

import { useCatJson } from '../../../hooks/use-cat-json';
import FaIconButton from '../../tiptap/tiptap-hotspot/font-awesome-icon-button';
import ModalComponent from '../modals/modal-component';

interface CatJSONModalProps {
    title: string;
    jsonLink: string;
}

export const CatJSONModal: React.FC<CatJSONModalProps> = ({ title, jsonLink }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data } = useQuery(useCatJson()([jsonLink], { enabled: !!jsonLink }));

    if (!data) {
        return (
            <Heading as="h4" size="sm" color="red.500">
                No available JSON to display
            </Heading>
        );
    }

    return (
        <div>
            <FaIconButton icon={faShieldCat} onClick={onOpen} cursor="pointer" size="2xl" color="gray" />
            <ModalComponent secondaryActionText="Close" header={`CAT JSON for Question: ${title}`} isOpen={isOpen} onClose={onClose} modalOptions={{ size: '6xl' }}>
                <Box p={3}>
                    <ReactJson theme={'hopscotch'} src={toJS(data)} />
                </Box>
            </ModalComponent>
        </div>
    );
};
