import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AccordionPanelProps, Box, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

interface AccordionComponentProps {
    isClosedOnRender?: boolean;
    title: string;
    headerActions?: JSX.Element;
    height?: AccordionPanelProps['height'];
}

const AccordionComponent: React.FC<PropsWithChildren<AccordionComponentProps>> = ({ isClosedOnRender, headerActions, title, children, height }) => (
    <Accordion mt={1} allowMultiple defaultIndex={isClosedOnRender ? [] : [0]}>
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box flex={'1'} textAlign={'left'}>
                        <Text fontWeight={700}>{title}</Text>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel data-testid={'accordion-panel'} height={height} pb={4}>
                {headerActions}
                {children}
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
);

export default AccordionComponent;
