import { useMemo } from 'react';

import { IFilterSearch } from '../components/base/interfaces/filter-search.interface';
import { ITag } from '../components/tags/interfaces/tag-props.interface';

type SavedTableFilter = {
    tags: ITag[];
    search: IFilterSearch['search'];
    questionVariantsSearchEnabled?: boolean;
};

export const useSavedSearchFilter = (tagRelationType: string) =>
    useMemo(() => {
        const key = ['conquest', 'search', ...tagRelationType.split(' ')].join('-').toLowerCase();
        const getSavedFilter = (): SavedTableFilter => {
            const item = localStorage.getItem(key);

            return item ? JSON.parse(item) : { tags: [], search: {}, questionVariantsSearchEnabled: false };
        };

        return {
            initialValues: getSavedFilter(),
            get: getSavedFilter,
            update: (newFilter: Partial<SavedTableFilter>) => localStorage.setItem(key, JSON.stringify({ ...getSavedFilter(), ...newFilter })),
            remove: () => localStorage.removeItem(key)
        };
    }, [tagRelationType]);
