import { FormControl, HStack } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';

import TagFilterTypeSelector from './tag-filter-type.selector';
import InputTextField from '../../base/input-text.field';
import { BaseProps } from '../../base/interfaces/base.props';
import { ITag } from '../../tags/interfaces/tag-props.interface';
import FaIconButton from '../../tiptap/tiptap-hotspot/font-awesome-icon-button';


const AddTagFilter: React.FC<BaseProps> = ({ addAction }) => {
    const [tagValue, setTagValue] = useState<string>('');
    const [tagTypeId, setTagTypeId] = useState<number | null>(null);
    const [tagType, setTagType] = useState<string | null>(null);
    const onAdd = () => {
        if (tagValue && tagType && tagTypeId && tagTypeId > 0) {
            const newTag: ITag = {
                id: tagTypeId,
                tagType,
                value: tagValue
            };

            setTagValue('');
            setTagTypeId(0);
            setTagType(null);
            addAction(newTag);
        }
    };
    const onTagTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.selectedOptions[0].value) {
            const selectedTagTypeId = parseInt(e.target.selectedOptions[0].value ?? '0');
            const selectedTagTypeName = e.target.selectedOptions[0].text;

            setTagTypeId(selectedTagTypeId);
            setTagType(selectedTagTypeName);
        }
    };
    const onTagValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTagValue(e.target.value);
    };

    return (
        <FormControl>
            <HStack align={'baseline'} gap={2}>
                <TagFilterTypeSelector
                    label='Select Tag Type'
                    id={'tagTypeId'}
                    placeholder='Select Tag Type'
                    isRequired={false}
                    allowEmptySelection={false}
                    onSelectionChange={onTagTypeChange}
                    selectedId={tagTypeId?.toString()}
                />
                <InputTextField
                    placeholder='e.g 24'
                    id='value'
                    inputVariant='flushed'
                    isRequired={false}
                    value={tagValue}
                    onChange={onTagValueChange}
                    focusBorderColor='teal.500'
                />
                <FaIconButton icon={faCheck} onClick={onAdd} tooltipLabel="Add tag" color='gray' cursor='pointer' />
            </HStack>
        </FormControl>
    );
};

export default AddTagFilter;
