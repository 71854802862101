import { FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';

import { useGetStatesForCountry } from './api/country.service.helper';

interface StateSelector {
    countryCode: string;
    stateCode: string | null;
    onChange: (stateCode: string | null) => void;
}

const selectorId = 'state-selector';
const StateSelector: React.FC<StateSelector> = ({ countryCode, stateCode, onChange }) => {
    const { data: states = [] } = useQuery(useGetStatesForCountry()([countryCode]));
    const options = states.map((state) => ({
        value: state.code,
        label: state.name
    }));
    const selectedState = stateCode ? options.find((option) => option.value === stateCode) : null;

    if (!states.length) {
        return null;
    }

    return (
        <FormControl pt={3}>
            <FormLabel htmlFor={selectorId} mt={4}>
                State
            </FormLabel>
            <Select
                isClearable={true}
                isDisabled={!states.length}
                id={selectorId}
                aria-label="Select state"
                value={selectedState}
                options={options}
                onChange={(selectedState) => onChange(selectedState ? selectedState.value : selectedState)}
                placeholder="Select state (optional)"
                styles={{
                    control: (base) => ({
                        ...base,
                        backgroundColor: 'transparent',
                        borderColor: 'inherit'
                    })
                }}
            />
        </FormControl>
    );
};

export default StateSelector;
