import { FetchResult } from '@apollo/client';

import { Queries } from './feature-flags.queries';
import BaseConquestApiService from '../api/base-conquest-api.service';

export default class FeatureFlagService extends BaseConquestApiService {
    public async getFeatureFlag(key: string, defaultValue: boolean): Promise<FetchResult<{ getFeatureFlag: boolean }>> {
        return this.graphqlClient.query({
            query: Queries.getFeatureFlag,
            variables: { key, defaultValue }
        });
    }
}
