import { Button, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { UseMutateFunction } from 'react-query';

import { SelectType } from '../../common/interfaces/types';
import AccordionComponent from '../base/accordion/accordion';
import CountrySelector from '../countries/country-selector';
import { MasterCourseDto } from '../curriculum/interface';

interface StrandCountrySelector {
    isMasterCoursesLoading: boolean;
    getMasterCourses: UseMutateFunction<MasterCourseDto[] | undefined, unknown, string>;
}

const StrandCountrySelector: React.FC<StrandCountrySelector> = ({ isMasterCoursesLoading, getMasterCourses }) => {
    const [selectedCountry, setSelectedCountry] = useState<SelectType>();
    const handleCountrySelect = (selectedCountry: SelectType) => {
        setSelectedCountry(selectedCountry);
    };

    return (
        <AccordionComponent height={350} title={'1. Select a country'}>
            <CountrySelector isRequired selectedCountry={selectedCountry} onChange={handleCountrySelect} />
            <Tooltip label={!selectedCountry && 'Please select a country first.'}>
                <Button isLoading={isMasterCoursesLoading} isDisabled={!selectedCountry} mt={3} onClick={() => selectedCountry?.value && getMasterCourses(selectedCountry.value)}>
                    Search master course
                </Button>
            </Tooltip>
        </AccordionComponent>
    );
};

export default StrandCountrySelector;
