import { gql } from '@apollo/client';

export class CourseRegionsQueries {
    static addCourseRegions = gql`
        mutation add_course_regions($regions: CourseRegionInput!) {
            addCourseRegions(input: $regions) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }
                }
            }
        }
    `;

    static removeCourseRegions = gql`
        mutation remove_course_regions($regions: CourseRegionInput!) {
            removeCourseRegions(input: $regions) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }
                }
            }
        }
    `;
}
