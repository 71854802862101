import { ColorModeScript } from '@chakra-ui/react';
import * as SentryBrowser from '@sentry/browser';
import * as React from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';

import { App } from './application';
import defaultTheme from './default.theme';
import reportWebVitals from './report-web-vitals';
import * as serviceWorker from './service-worker';
import authenticationService from './services/authentication.service';
import store, { StoreContext } from './stores';

// Set initial theme to light - force this to resolve a problem on some browsers:
const myLocalStorage = window.localStorage;

myLocalStorage.setItem('chakra-ui-color-mode', 'light');

SentryBrowser.init({
    dsn: window._env_.SENTRY_DSN,
    environment: window._env_.SENTRY_ENVIRONMENT
});

const app = () =>
    // eslint-disable-next-line react/no-render-return-value
    createRoot(document.getElementById('root') as HTMLElement).render(
        <React.StrictMode>
            <StoreContext.Provider value={store}>
                <ColorModeScript initialColorMode={defaultTheme.config.initialColorMode} />
                <App />
            </StoreContext.Provider>
        </React.StrictMode>
    );

// eslint-disable-next-line @typescript-eslint/no-empty-function
authenticationService.authentication(app).then(() => {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
