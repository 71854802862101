import { Flex, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';

import { useAddDefaultCourseDeliver, useRemoveDefaultCourseDeliver } from './default-course.service.helper';
import { DefaultCourseDto } from './interface';
import ServiceErrorMessage2 from '../../common/toast-messages/service-error-message-2';
import serviceSuccessMessage from '../../common/toast-messages/service-success-message';
import { ReactQueryError } from '../../hooks/use-conquest-query';
import PrimaryButton from '../base/buttons/primaryButton/primary-button';
import WarningButton from '../base/buttons/warningButton/warning-button';
import Dropdown from '../base/dropdown/dropdown';
import { useGetDeliveredCourses } from '../courses/course.service.helper';

interface DefaultCourseDropdownProps {
    refetchDefaultCourses: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<DefaultCourseDto[] | undefined, ReactQueryError>>;
    defaultCourseId?: string;
    courseId?: string;
    yearGroupId: number;
    countryCode: string;
    stateCode: string | null;
    id?: number;
}

const DefaultCourseDropdown: React.FC<DefaultCourseDropdownProps> = ({ refetchDefaultCourses, id, countryCode, stateCode, courseId, yearGroupId }) => {
    const { data: deliveredCoursesList = [] } = useQuery(useGetDeliveredCourses()([]));
    const courseDropdownOptions = deliveredCoursesList
        .filter((item) => !item.isArchived)
        .map((course) => ({
            label: course.courseItems[0]?.title,
            id: course.id
        }));
    const [selectedCourse, setSelectedCourse] = useState(courseId || '');
    const toast = useToast();
    const { mutate: addDefaultCourseDeliver } = useMutation(useAddDefaultCourseDeliver(), {
        onSuccess: () => {
            refetchDefaultCourses();
            toast(serviceSuccessMessage('Default course successfully added.'));
        },
        onError: (error) => {
            toast(ServiceErrorMessage2(JSON.stringify(error)));
        }
    });
    const { mutateAsync: removeDefaultCourseDeliver } = useMutation(useRemoveDefaultCourseDeliver(), {
        onSuccess: () => {
            refetchDefaultCourses();
            toast(serviceSuccessMessage('Default course successfully updated.'));
        },
        onError: (error) => {
            toast(ServiceErrorMessage2(JSON.stringify(error)));
        }
    });

    return (
        <Flex>
            <Dropdown
                allowEmptySelection={true}
                options={courseDropdownOptions}
                value={selectedCourse}
                onChange={(e) => {
                    setSelectedCourse(e.target.value);
                }}
            />
            <PrimaryButton
                mr={2}
                onClick={async () => {
                    if (!selectedCourse && id) {
                        await removeDefaultCourseDeliver(id);
                    } else if (id) {
                        await removeDefaultCourseDeliver(id);
                        await addDefaultCourseDeliver({
                            courseId: selectedCourse,
                            stateCode,
                            yearGroupId,
                            countryCode
                        });
                    } else {
                        addDefaultCourseDeliver({
                            yearGroupId,
                            countryCode,
                            stateCode,
                            courseId: selectedCourse
                        });
                    }
                }}
            >
                Save
            </PrimaryButton>
            <WarningButton ariaLabel={'reset-button'} onClick={() => id && removeDefaultCourseDeliver(id)} data-testid={'delete-button'} colorScheme={'red'} ml={3}>
                Reset
            </WarningButton>
        </Flex>
    );
};

export default DefaultCourseDropdown;
