import { FetchResult } from '@apollo/client';

import { StrandQueries } from './strand.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { AddStrandInput, StrandDto, StrandListDto, StrandListSearchArgs, UpdateStrandInput } from '../strand.interface';

export default class StrandService extends BaseConquestApiService {
    public async addStrand(input: AddStrandInput): Promise<FetchResult<{ addStrand: StrandDto }>> {
        return this.graphqlClient.mutate({
            mutation: StrandQueries.addStrand,
            variables: { input }
        });
    }

    public async getStrands(searchArgs: StrandListSearchArgs): Promise<FetchResult<{ getStrands: StrandListDto }>> {
        return this.graphqlClient.query({
            query: StrandQueries.getStrands,
            variables: { searchArgs }
        });
    }

    public async getStrandById(id: string): Promise<FetchResult<{ getStrandByIdContent: StrandDto }>> {
        return this.graphqlClient.query({
            query: StrandQueries.getStrandById,
            variables: { getStrandByIdContentId: id }
        });
    }

    public async removeStrand(id: string): Promise<FetchResult<{ removeStrandContent: boolean }>> {
        return this.graphqlClient.query({
            query: StrandQueries.removeStrand,
            variables: { removeStrandContentId: id }
        });
    }

    public async updateStrand(input: UpdateStrandInput): Promise<FetchResult<{ updateStrandByIdContent: StrandDto }>> {
        return this.graphqlClient.query({
            query: StrandQueries.updateStrand,
            variables: { input }
        });
    }
}
