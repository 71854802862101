import React from 'react';

export const getRowStyle = (isDragging: boolean, draggableStyle?: React.CSSProperties) => ({
    border: isDragging ? '2px solid teal' : 'none',
    ...draggableStyle
});

export const getTableStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver ? '2px dashed teal' : 'none',
    marginTop: '5px'
});
