import { Flex, Heading } from '@chakra-ui/react';
import * as React from 'react';

import { useNavigationAuthoringItems } from './sidebar.helper';
import NavItem from '../navbar-item';
import UserInfoPanel from '../user-info-panel';

const Sidebar: React.FC = () => {
    const navigationItems = useNavigationAuthoringItems();

    return (
        <Flex
            pos={'absolute'}
            left={'2'}
            top={'0.5'}
            marginTop={'1.5vh'}
            boxShadow={'0 4px 12px 0 rgba(0, 0, 0, 0.05)'}
            borderRadius={5}
            w={'224px'}
            flexDir={'column'}
            justifyContent={'space-between'}
            bg={'ghostwhite'}
            alignItems={'start'}
        >
            <Flex p={'5%'} flexDir={'column'} alignItems={'flex-start'} as={'nav'}>
                {navigationItems.map((item) => {
                    if (item && !item.icon && !item.routeTo) {
                        return (
                            <Heading key={item.title} size={'sm'} mt={6} mb={2}>
                                {item.title}
                            </Heading>
                        );
                    } else if (!item) {
                        return null;
                    }

                    return <NavItem key={item.routeTo} title={item.title} icon={item.icon} routeTo={item.routeTo} />;
                })}
            </Flex>
            <Flex p={'3%'} flexDir={'column'} alignItems={'flex-start'} mb={4}>
                <UserInfoPanel />
            </Flex>
        </Flex>
    );
};

export default Sidebar;
