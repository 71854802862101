import { gql } from '@apollo/client';

export class TagAddQueries {
    static readonly createTag = gql`
        mutation CreateTag($data: TagInputContent!) {
            createTagContent(data: $data) {
                relationId
                relationType
                tagType
                value
                id
            }
        }
    `;
}
