import { gql } from '@apollo/client';

export class CatValidatorQueries {
    static getCatQuestionMigratedCount = gql`
        query getCatQuestionMigratedCount {
            getCatQuestionMigratedCount {
                type
                count
                questionCount
            }
        }
    `;

    static validateAllCatQuestions = gql`
        query ValidateAllLearnosity($input: [ValidateCatQuestionInput!]!) {
            validateAllLearnosityQuestions(input: $input) {
                Id
                QuestionId
                CultureCode
                StudentTitle
                TeacherTitle
                Link
                Description
                CultureCodeCorrect
                DescriptionCorrect
                Errors
            }
        }
    `;

    static validateCatQuestions = gql`
        query ValidateLearnosity($input: [ValidateCatQuestionInput!]!) {
            validateLearnosityQuestions(input: $input) {
                Id
                QuestionId
                CultureCode
                StudentTitle
                TeacherTitle
                Link
                Description
                CultureCodeCorrect
                DescriptionCorrect
                Errors
            }
        }
    `;

    static validateCatQuestionVariants = gql`
        query ValidateLearnosityQuestionVariants($input: [ValidateCatQuestionInput!]!) {
            validateLearnosityQuestionVariants(input: $input) {
                Id
                QuestionId
                QuestionVariantId
                QuestionItemId
                CultureCode
                StudentTitle
                TeacherTitle
                Link
                CultureCodeCorrect
                QuestionVariantTitleCorrect
                TeacherTitleCorrect
                SortOrderCorrect
                Errors
            }
        }
    `;

    static validateCatMcqQuestions = gql`
        query Query($input: [ValidateCatContentInput!]!) {
            validateLearnosityMcqQuestions(input: $input) {
                GirlId
                QuestionItemId
                QuestionVariantId
                Link
                HorizontalOrientationCorrect
                ShuffleAndRandomiseCorrect
            }
        }
    `;

    static validateCatDropdownQuestions = gql`
        query Query($input: [ValidateCatContentInput!]!) {
            validateLearnosityDropdownQuestions(input: $input) {
                GirlId
                Link
                QuestionItemId
                QuestionVariantId
                ShuffleAndRandomiseCorrect
                StoredAnswersPresent
                DropdownBlockPresent
            }
        }
    `;

    static validateCatDnDQuestions = gql`
        query ValidateLearnosityDnDQuestions($input: [ValidateCatContentInput!]!) {
            validateLearnosityDnDQuestions(input: $input) {
                GirlId
                Title
                QuestionItemId
                QuestionVariantId
                Link
                AllStoredAnswersPresent
                DuplicateResponsesPresent
                QuestionVariantId
                ShuffleAndRandomiseCorrect
                DnDTargetsPresent
                DndSourceBlocksPresent
            }
        }
    `;

    static getCatQuestionSearch = gql`
        query GetCatQuestionSearch($input: SearchCatQuestionInput!) {
            getCatQuestionSearch(input: $input) {
                QuestionVariantId
                QuestionItemId
                Title
                Link
                GirlId
            }
        }
    `;
}
