export class ValidationConstants {
    // Title
    static titleMinimumLength = 3;
    static titleMaximumLength = 128;
    static titleMinimumValidationMessage = 'The title is too short';
    static titleMaximumValidationMessage = 'The title is too long';
    static titleRequiredValidationMessage = 'A title is required';

    // Teacher title
    static teacherTitleMinimumLength = 3;
    static teacherTitleMaximumLength = 512;
    static teacherTitleMinimumValidationMessage = 'The teacher title is too short';
    static teacherTitleMaximumValidationMessage = 'The teacher title is too long';
    static teacherTitleRequiredValidationMessage = 'A teacher title is required';

    // Strand
    static strandAbbreviationMaximumLength = 2;
    static strandAbbreviationMaximumValidationMessage =
        'The strand abbreviation must be a maximum of two characters, composed exclusively of uppercase letters and digits, with no special characters allowed.';

    // locale
    static localeRequiredValidationMessage = 'A locale must be selected';
    static localeIdRequiredValidationMessage = 'A valid locale ID must be provided';

    // country
    static countryRequiredValidationMessage = 'A country must be selected';

    // Content
    static contentRequiredValidationMessage = 'Content is required';

    // Name
    static nameRequiredValidationMessage = 'A name is required';

    // Language code
    static languageCodeRequiredValidationMessage = 'A language code is required';

    // Code
    static codeRequiredValidationMessage = 'A code is required';

    // Description
    static descriptionMinimumValidationMessage = 'The description is too short';
    static descriptionMaximumValidationMessage = 'The description is too long';
    static descriptionRequiredValidationMessage = 'A description is required';

    // Question
    static questionContentRequiredValidationMessage = 'You must input some question content';
    static questionParagraphContentRequiredValidationMessage = 'Empty paragraphs are not allowed [Question]';
    static questionListParagraphContentRequiredValidationMessage = 'Empty paragraphs in ordered/bullet list are not allowed [Question]';
    static invalidaCustomParagraphMessage = 'One or more paragraphs in Question content are invalid';

    // Question Variant
    static questionVariantIdRequiredValidationMessage = 'A valid Question Variant ID must be provided';

    // Hint
    static hintParagraphContentRequiredValidationMessage = 'Empty paragraphs are not allowed [Hint]';
    static hintListParagraphContentRequiredValidationMessage = 'Empty paragraphs in ordered/bullet list [Hint]';

    // Worked solution
    static workedSolutionParagraphContentRequiredValidationMessage = 'Empty paragraphs are not allowed [Worked Solution]';
    static workedSolutionListParagraphContentRequiredValidationMessage = 'Empty paragraphs in ordered/bullet list are not allowed [Worked Solution]';

    // Answer
    static answerRequiredValidationMessage = 'A answer is required';

    // Percentage
    static percentageMinimumValidation = 'A percentage is between 0 to 100';
    static percentageMaximumValidation = 'A percentage is between 0 to 100';
    static percentageMinimum = 0;
    static percentageMaximum = 100;

    // Feedback
    static feedbackMinimumLength = 3;
    static feedbackMaximumLength = 512;
    static feedbackMinimumValidationMessage = 'The feedback provided is too short';
    static feedbackMaximumValidationMessage = 'The feedback provided is too long';

    // Outcome Code required message
    static outcomeCodeDescriptionMinimumLength = 3;
    static outcomeCodeDescriptionMaximumLength = 512;
    static outcomeCodeDescriptionMinimumValidationMessage = 'The outcome code description provided is too short';
    static outcomeCodeDescriptionMaximumValidationMessage = 'The outcome code description provided is too long';
    static outcomeCodeDescriptionRequiredValidationMessage = 'A description is required';

    static validGuid = 'A valid UUID is required';

    static defaultlocaleIdRequired = 'A default locale is required';

    // Internal notes
    static internalNotesMaxLength = 1028;
    static internalNotesmumValidationMessage = 'The internal notes are too long';

    // Login credential
    static usernameRequired = 'Username required';
    static passwordRequired = 'Password required';
}
