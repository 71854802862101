import { UseToastOptions } from '@chakra-ui/react';

const ServiceSuccessMessage = (success: string): UseToastOptions => {
    const serviceSuccessMessage = (success: string): UseToastOptions => {
        return {
            position: 'bottom',
            title: 'Service Success',
            description: success,
            status: 'success',
            duration: 5000,
            isClosable: true
        };
    };

    return serviceSuccessMessage(success);
};

export default ServiceSuccessMessage;
