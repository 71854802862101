import { FormControl, FormHelperText, FormLabel, Input, InputProps } from '@chakra-ui/react';
import * as React from 'react';

interface InputTextFieldProps extends InputProps {
    id: string
    title?: string
    titleColour?: string
    helperText?: string
    isRequired?: boolean
    isInvalid?: boolean
    inputVariant?: string
    invalidHelperText?: string
}

const InputTextField: React.FC<InputTextFieldProps> = ({ id, title, titleColour, helperText, inputVariant, isRequired, isInvalid, invalidHelperText, ...rest }) =>
(
    <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <FormLabel mt={3} htmlFor={id} color={titleColour}>{title}</FormLabel>
        <Input id={id} type={'text'} variant={inputVariant} errorBorderColor={'red.300'} {...rest} />
        {isInvalid ? (
            <FormHelperText data-testid={'invalid-helper-text'} color={'red.300'}>
                {invalidHelperText}
            </FormHelperText>
        ) : helperText ? (
            <FormHelperText data-testid={'valid-helper-text'}>{helperText}</FormHelperText>
        ) : undefined}
    </FormControl>
);

export default InputTextField;
