import React from 'react';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/lib/core';

import PrimaryButton from './primaryButton/primary-button';

interface CsvDownloadButtonProps {
    data: string | Data | (() => string | Data);
    isDisabled: boolean;
    filename: string;
}

const CsvDownloadButton: React.FC<CsvDownloadButtonProps> = ({ data, isDisabled, filename }) => (
    <CSVLink data={data} filename={filename}>
        <PrimaryButton isDisabled={isDisabled}>Download CSV</PrimaryButton>
    </CSVLink>
);

export default CsvDownloadButton;
