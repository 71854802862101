import { FetchResult } from '@apollo/client';

import { CountryQueries } from './country.queries';
import BaseConquestApiService from '../../../api/base-conquest-api.service';
import { ICountry } from '../interface/countries.dto';
import { IState } from '../interface/states.dto';

export default class CountryService extends BaseConquestApiService {
    public async getAllCountries(): Promise<FetchResult<{ getAllCountriesContent: ICountry[] }>> {
        return this.graphqlClient.query({
            query: CountryQueries.getAllCountries
        });
    }

    public async getStatesForCountry(code: string) {
        return this.graphqlClient.query<{ getCountryContentByCode: { states: IState[] } }>({
            query: CountryQueries.getStatesForCountry,
            variables: { code }
        });
    }
}
