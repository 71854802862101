import { gql } from '@apollo/client';

export class DefaultCourseQueries {
    static getDefaultCoursesByLocationDeliver = gql`
        query GetDefaultCoursesByLocationDeliver($input: DefaultCourseGetInputDeliver!) {
            getDefaultCourseByLocationDeliver(input: $input) {
                id
                courseId
                yearGroup {
                    yearLevel
                    id
                }
                countryCode
            }
        }
    `;

    static addDefaultCourseDeliver = gql`
        mutation Mutation($input: DefaultCourseAddInputDeliver!) {
            addDefaultCourseDeliver(input: $input) {
                countryCode
                courseId
                stateCode
            }
        }
    `;

    static updateDefaultCourseDeliver = gql`
        mutation Mutation($input: DefaultCourseUpdateInputDeliver!) {
            updateDefaultCourseByIdDeliver(input: $input) {
                courseId
                countryCode
                stateCode
            }
        }
    `;

    static removeDefaultCourseDeliver = gql`
        mutation Mutation($removeDefaultCourseDeliverId: Int!) {
            removeDefaultCourseDeliver(id: $removeDefaultCourseDeliverId)
        }
    `;
}
