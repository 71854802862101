import React from 'react';

import Utility from '../../common/utility';
import AccordionComponent from '../base/accordion/accordion';
import Dropdown from '../base/dropdown/dropdown';
import { MasterCourseHierarchyDto, SectionChildNodeDto, TreeSectionChildNodeDto } from '../curriculum/interface';

interface StrandParentNodeDropdownProps {
    setSelectedParentNode: React.Dispatch<React.SetStateAction<SectionChildNodeDto[] | undefined>>;
    masterCourseHierarchy: MasterCourseHierarchyDto | undefined;
    selectedMasterCourseId: string | undefined;
}
const StrandParentNodeDropdown: React.FC<StrandParentNodeDropdownProps> = ({ selectedMasterCourseId, setSelectedParentNode, masterCourseHierarchy }) => {
    const updatedSections: TreeSectionChildNodeDto[] = Utility.updateKeys(masterCourseHierarchy?.standardTree || [], { description: 'name', childNodes: 'children' });
    const parentNodeOptions = updatedSections.map((tree) => {
        return {
            id: tree.id,
            label: tree.name
        };
    });

    return (
        <AccordionComponent isClosedOnRender={!!selectedMasterCourseId} title={'3. Select a parent node'}>
            {parentNodeOptions && (
                <Dropdown
                    allowEmptySelection
                    onChange={(e) => {
                        const parentNode = updatedSections.filter((tree) => tree.id === e.target.value);

                        parentNode.length && setSelectedParentNode(parentNode);
                    }}
                    options={parentNodeOptions}
                />
            )}
        </AccordionComponent>
    );
};

export default StrandParentNodeDropdown;
