import { Heading, VStack, Link } from '@chakra-ui/react';
import { faDiamondTurnRight } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

import FaIconButton from '../../tiptap/tiptap-hotspot/font-awesome-icon-button';
import CopyGuidButton from '../buttons/copyGuidButton/copy-to-clipboard-button';

interface CatJSONLinkToProps {
    link?: string;
}

export const CatJSONLinkTo: React.FC<CatJSONLinkToProps> = ({ link }) => {
    if (!link) {
        return (
            <Heading as="h4" size="sm" color="red.500">
                Not available
            </Heading>
        );
    }

    return (
        <VStack gap={1}>
            <Link href={link} isExternal>
                <FaIconButton onClick={() => void 0} icon={faDiamondTurnRight} cursor="pointer" size="2xl" color="gray" />
            </Link>
            <CopyGuidButton label={link} altLabel="Copy JSON link" clipboardMessage="link" />
        </VStack>
    );
};
