import { Select } from '@chakra-ui/react';
import React from 'react';

interface CurriculumVersionDropdownInterface {
    setSelectedMasterCourseVersion: React.Dispatch<React.SetStateAction<number | undefined>>;
    maxVersion: number;
    selectedVersion: number | undefined;
    isDisabled: boolean;
}

const CurriculumVersionDropdown: React.FC<CurriculumVersionDropdownInterface> = ({ setSelectedMasterCourseVersion, maxVersion, selectedVersion, isDisabled }) => {
    const versions = Array.from({ length: maxVersion }, (_, i) => ({
        id: i + 1,
        label: `Version ${i + 1}`
    }));
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        !isDisabled && setSelectedMasterCourseVersion(Number(event.target.value));
    };

    return (
        <Select value={selectedVersion} onChange={handleChange} isDisabled={isDisabled}>
            {versions.map((version) => (
                <option key={version.id} value={version.id}>
                    {version.label}
                </option>
            ))}
        </Select>
    );
};

export default CurriculumVersionDropdown;
