import YearGroupService from './year-group.service';
import { useConquestQuery } from '../../../hooks/use-conquest-query';

export const getYearGroupsKey = 'getYearGroupsKey';

const yearGroupService = new YearGroupService();

export const useGetAllYearGroups = () =>
    useConquestQuery(async () => {
        const { data } = await yearGroupService.getAllYearGroups();

        return data?.getAllYearGroups;
    }, getYearGroupsKey);
