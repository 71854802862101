import { gql } from '@apollo/client';

export class CourseDeleteQueries {
    static removeCourse = gql`
        mutation RemoveCourse($removeCourseId: String!) {
            removeCourse(id: $removeCourseId)
        }
    `;

    static deleteCourseItem = gql`
        mutation remove_course_item($id: String!) {
            removeCourseItem(id: $id)
        }
    `;
}
