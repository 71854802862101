import { gql } from '@apollo/client';

export class CourseChildrenQueries {
    static addUnitsOfWorkToCourse = gql`
        mutation add_units_of_work_to_course($id: ID!, $uowInCourse: [UnitOfWorkInCourseInput!]!) {
            addUnitsOfWorkToCourse(input: { courseId: $id, unitsOfWorkInCourse: $uowInCourse }) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }
                    linkedUnitsOfWork: unitsOfWorkInCourse {
                        id
                    }
                }
            }
        }
    `;

    static removeUnitsOfWorkFromCourse = gql`
        mutation remove_units_of_work_from_course($courseId: ID!, $idsToRemove: [ID!]!) {
            removeUnitsOfWorkFromCourse(input: { courseId: $courseId, itemIdsToRemove: $idsToRemove }) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }
                    linkedUnitsOfWork: unitsOfWorkInCourse {
                        id
                        sortOrder
                        unitOfWork {
                            id
                            unitOfWorkItems {
                                id
                                localeId
                                title
                                isPrimary
                            }
                        }
                    }
                }
            }
        }
    `;

    static addContentItemsToCourse = gql`
        mutation add_content_items_to_course($courseId: ID!, $contentItems: [ContentItemInCourseInput!]!) {
            addContentItemsToCourse(input: { courseId: $courseId, contentItemsInCourse: $contentItems }) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }

                    linkedContentItems: contentItemsInCourse {
                        id
                        contentItem {
                            id
                        }
                    }
                }
            }
        }
    `;

    static removeContentItemsFromCourse = gql`
        mutation remove_content_items_to_course($courseId: ID!, $itemIdsToRemove: [ID!]!) {
            removeContentItemsFromCourse(input: { courseId: $courseId, itemIdsToRemove: $itemIdsToRemove }) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                    }
                    regions {
                        regionId
                    }

                    linkedContentItems: contentItemsInCourse {
                        id
                        contentItem {
                            id
                            contentMedia {
                                id
                                isPrimary
                                localeId
                                title
                                content
                            }
                        }
                    }
                }
            }
        }
    `;
}
