import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { HStack, IconButton } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import Dropdown from '../dropdown/dropdown';

interface PaginationProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pageCount: number;
    pageSize: number;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination: React.FC<PropsWithChildren<PaginationProps>> = ({ page, setPage, pageCount, pageSize, setPageSize, children }) => {
    const paginationOptions = [10, 20, 30, 40, 50].map((value) => ({
        id: value,
        label: `Show ${value}`
    }));

    return (
        <HStack justify={'center'} spacing={'12px'}>
            <IconButton data-testid={'first-page-button'} aria-label={'First Page'} onClick={() => setPage(1)} isDisabled={page === 1} icon={<ArrowLeftIcon h={3} w={3} />} />
            <IconButton
                data-testid={'previous-page-button'}
                aria-label={'Previous Page'}
                onClick={() => setPage(--page)}
                isDisabled={page === 1 || !pageCount}
                icon={<ChevronLeftIcon h={6} w={6} />}
            />
            <IconButton
                data-testid={'next-page-button'}
                aria-label={'Next Page'}
                onClick={() => setPage(++page)}
                isDisabled={page === pageCount || !pageCount}
                icon={<ChevronRightIcon h={6} w={6} />}
            />
            <IconButton
                data-testid={'last-page-button'}
                aria-label={'Last Page'}
                onClick={() => setPage(pageCount)}
                isDisabled={page === pageCount || !pageCount}
                icon={<ArrowRightIcon h={3} w={3} />}
            />
            <span data-testid={'page'}>
                Page{' '}
                <strong data-testid={'page-display'}>
                    {page} of {pageCount === 0 ? 1 : pageCount}
                </strong>{' '}
            </span>
            <span>
                | Go to page:{' '}
                <input
                    data-testid={'number-input'}
                    type={'number'}
                    defaultValue={1}
                    onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 1;

                        setPage(page);
                    }}
                    style={{ width: '100px' }}
                />
            </span>{' '}
            <Dropdown
                width={'sm'}
                options={paginationOptions}
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    setPage(1);
                }}
            />
            {children}
        </HStack>
    );
};
