import { gql } from '@apollo/client';

export class CourseAddQueries {
    static addCourse = gql`
        mutation add_course($course: CourseCreateInput!) {
            addCourse(input: $course) {
                id
                courseItems {
                    id
                    isPrimary
                    localeId
                    title
                    internalNotes
                }
            }
        }
    `;

    static addCourseItem = gql`
        mutation add_course_item($courseItem: CourseItemCreateInput!) {
            addCourseItem(input: $courseItem) {
                ... on CourseAuthoringDto {
                    id
                    courseItems {
                        id
                        isPrimary
                        localeId
                        title
                        internalNotes
                    }
                }
            }
        }
    `;
}
