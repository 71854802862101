import axios from "axios"

import { useConquestQuery } from "./use-conquest-query";

export const useCatJson = () => {
    const getCatJson = async (url: string) => {

        return axios.get(url);
    };

    return useConquestQuery(getCatJson, 'getCatJson');
}